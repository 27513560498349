import React from "react";
import "../styles/order_confirm.css";
import { Link } from "react-router-dom";

const OrderConfirm = () => {
  return (
    <div class="super_main">
      <div class="top_heading">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-auto">
              <h3>Order Confirm</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="container order_confirm">
        <div class="row justify-content-center">
          <div class="col-md-6 content_con">
            <img
              class="img-fluid"
              target="_blank"
              src={require("../assets/images/success-img.png.png")}
              alt="v"
            />
            <h1>Message</h1>
            <p>
              Here is your Order ID: Please Save it to see your order details
            </p>
            <p>Order ID: 2</p>
            <Link to={"/your_orders"}>
              <button>View Order</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirm;
