import React, { useEffect } from 'react';
import '../styles/SimplePopup.css'; // Import CSS file for styling

const SimplePopup = ({ onClose, children }) => {

    useEffect(() => {
        // Prevent scrolling on the body element when the popup is open
        document.body.style.overflow = 'hidden';
        // Cleanup function to allow scrolling when the popup is closed
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);
    return (
        <div className="popup-overlay">
            <div className="popup">
                <div className="popup-content">{children}</div>
            </div>
        </div>
    );
};

export default SimplePopup;
