import React from 'react'
import MainLayout from '../layouts/MainLayout'
import ProductDetailsBody from '../containers/ProductDetails'

const ProductDetails = () => {
  return (
    <MainLayout>
        <ProductDetailsBody />
    </MainLayout>
  )
}

export default ProductDetails