import React from "react";
import YourOrdersBody from "../containers/YourOrders";
import MainLayout from "../layouts/MainLayout";

const YourOrders = () => {
  return (
    <MainLayout>
      <YourOrdersBody />
    </MainLayout>
  );
};

export default YourOrders;
