import React from "react";
import MainLayout from "../layouts/MainLayout";
import SelectItemsBody from "../containers/SelectItems";

const SelectItems = () => {
  return (
    <MainLayout>
      <SelectItemsBody />
    </MainLayout>
  );
};

export default SelectItems;
