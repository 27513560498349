import React from 'react'

const FAQ = () => {
    return (
        <>
            <div className="container-lg">
                <div className="faq_section">
                    <h2>Frequently Asked Questions</h2>
                    <div className="">
                        <div className="row">
                            {/* <div className="col-md-4">
                                <div className="nav flex-column nav-pills about_question_sec aqs_title_con" id="v-pills-tab"
                                    role="tablist" aria-orientation="vertical">
                                    <button className="active" id="v-pills-home-tab" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home"
                                        aria-selected="true">
                                        <div className="aqs_content">
                                            <div className="aqs_image">
                                                <img src={require('../assets/icons/Mask group.png')} alt="" />
                                            </div>
                                            <div className="aqs_title">
                                                General Questions
                                            </div>

                                        </div>
                                    </button>
                                    <button className="" id="v-pills-profile-tab" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile"
                                        aria-selected="false">
                                        <div className="aqs_content">
                                            <div className="aqs_image">
                                                <img src={require('../assets/icons/Mask group (1).png')} alt="" />
                                            </div>
                                            <div className="aqs_title">
                                                Order & Cart
                                            </div>

                                        </div>
                                    </button>
                                    <button className="" id="v-pills-messages-tab" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages"
                                        aria-selected="false">
                                        <div className="aqs_content">
                                            <div className="aqs_image">
                                                <img src={require('../assets/icons/Mask group (2).png')} alt="" />
                                            </div>
                                            <div className="aqs_title">
                                                Payment & Credit
                                            </div>

                                        </div>
                                    </button>
                                    <button className="" id="v-pills-settings-tab" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings"
                                        aria-selected="false">
                                        <div className="aqs_content">
                                            <div className="aqs_image">
                                                <img src={require('../assets/icons/Mask group (3).png')} alt="" />
                                            </div>
                                            <div className="aqs_title">
                                                Shipping
                                            </div>

                                        </div>
                                    </button>
                                    <button className="" id="v-pills-5-tab" data-bs-toggle="pill" data-bs-target="#v-pills-5"
                                        type="button" role="tab" aria-controls="v-pills-5" aria-selected="false">
                                        <div className="aqs_content">
                                            <div className="aqs_image">
                                                <img src={require('../assets/icons/Mask group (4).png')} alt="" />
                                            </div>
                                            <div className="aqs_title">
                                                Licence & Invoice
                                            </div>

                                        </div>
                                    </button>
                                </div>
                                <div className="nav nav-pills nav_for_phone" id="v-pills-tab" role="tablist"
                                    aria-orientation="vertical">
                                    <button className="phone_accordian_btns" id="v-pills-profile-tab" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile"
                                        aria-selected="false"><img src={require('../assets/icons/order.png')}
                                            alt="" /><span>Order</span></button>
                                    <button className="phone_accordian_btns" id="v-pills-messages-tab" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages"
                                        aria-selected="false"><img src={require('../assets/icons/Payment.png')}
                                            alt="" /><span>Payment</span></button>
                                    <button className="phone_accordian_btns" id="v-pills-settings-tab" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings"
                                        aria-selected="false"><img src={require('../assets/icons/Delivery.png')}
                                            alt="" /><span>Delivery</span></button>
                                    <button className="phone_accordian_btns" id="v-pills-5-tab" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-5" type="button" role="tab" aria-controls="v-pills-5"
                                        aria-selected="false"><img src={require('../assets/icons/Refunds.png')}
                                            alt="" /><span>Refunds</span></button>
                                </div>
                            </div> */}
                            <div className="col-md-12">
                                <div className="ques_answer_sec">
                                    <div className="tab-content" id="v-pills-tabContent">
                                        <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                                            aria-labelledby="v-pills-home-tab" tabindex="0">
                                            <div className="accordion" id="accordion1">
                                                <div className="accordion-item">
                                                    <h3 className="accordion-header" id="heading1">
                                                        <button className="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapse1"
                                                            aria-expanded="false" aria-controls="collapse1">
                                                            How do I return an item?
                                                        </button>
                                                    </h3>
                                                    <div id="collapse1" className="accordion-collapse collapse"
                                                        aria-labelledby="heading1" data-bs-parent="#accordion1">
                                                        <div className="accordion-body">
                                                            Since each product is unique we only offer returns if the item is faulty or incorrect.  Send an email to <span style={{ fontWeight: 600 }}>brainbulbinfo@gmail.com</span> with a picture of your product and your order number.  We will respond with further instructions.
                                                            *note: all returns must be initiated before a 4 weeks time period after item has been received.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion" id="accordion2">
                                                <div className="accordion-item">
                                                    <h3 className="accordion-header" id="heading2">
                                                        <button className="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapse2"
                                                            aria-expanded="false" aria-controls="collapse2">
                                                            Is there a way I can learn how to enter better prompts to get more unique images?
                                                        </button>
                                                    </h3>
                                                    <div id="collapse2" className="accordion-collapse collapse"
                                                        aria-labelledby="heading2" data-bs-parent="#accordion2">
                                                        <div className="accordion-body">
                                                            On the image generation screen there’s an option to learn how it works by selecting the option near the text bar.  We’ve also provided a link to it here!  (Link)
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion" id="accordion3">
                                                <div className="accordion-item">
                                                    <h3 className="accordion-header" id="heading3">
                                                        <button className="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapse3"
                                                            aria-expanded="false" aria-controls="collapse3">
                                                            What is the limited designs page for, and how does it work?
                                                        </button>
                                                    </h3>
                                                    <div id="collapse3" className="accordion-collapse collapse"
                                                        aria-labelledby="heading3" data-bs-parent="#accordion3">
                                                        <div className="accordion-body">
                                                            If you’re new to using AI driven image generators, and you’d like to see what’s possible or just select an image without having to come up with a prompt, our limited designs page is a section/gallery of photos generated by us personally.  We will also include the prompt used to achieve each image.  You can then use whichever image you like on whatever item you want!
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion" id="accordion4">
                                                <div className="accordion-item">
                                                    <h3 className="accordion-header" id="heading4">
                                                        <button className="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapse4"
                                                            aria-expanded="false" aria-controls="collapse4">
                                                            Are there special deals or coupons I can use?
                                                        </button>
                                                    </h3>
                                                    <div id="collapse4" className="accordion-collapse collapse"
                                                        aria-labelledby="heading4" data-bs-parent="#accordion4">
                                                        <div className="accordion-body">
                                                            Currently we are experimenting with various methods of offering discounts.  Occasionally, we may have special discount codes posted to the bio or stories of our various social medias.  We also may send you special deals to your email if you agree to subscribe to our newsletter.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion" id="accordion5">
                                                <div className="accordion-item">
                                                    <h3 className="accordion-header" id="heading5">
                                                        <button className="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapse5"
                                                            aria-expanded="false" aria-controls="collapse5">
                                                            Where is our physical store located?
                                                        </button>
                                                    </h3>
                                                    <div id="collapse5" className="accordion-collapse collapse"
                                                        aria-labelledby="heading5" data-bs-parent="#accordion5">
                                                        <div className="accordion-body">
                                                            We are based in the state of Texas, however we don’t currently offer any physical store fronts yet as we’re focused on building and connecting with an online community first!
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion" id="accordion6">
                                                <div className="accordion-item">
                                                    <h3 className="accordion-header" id="heading6">
                                                        <button className="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapse6"
                                                            aria-expanded="false" aria-controls="collapse6">
                                                            If I’ve already purchased a product and I want to leave a review, how do I do that?
                                                        </button>
                                                    </h3>
                                                    <div id="collapse6" className="accordion-collapse collapse"
                                                        aria-labelledby="heading6" data-bs-parent="#accordion6">
                                                        <div className="accordion-body">
                                                            There’s not currently an option to leave reviews, however you can send an email and give us feedback at <span style={{ fontWeight: 600 }}>brainbulbinfo@gmail.com</span>.  We do our best to read and respond to all emails!  You can also follow our socials on TikTok, Instagram, X, YouTube, and FaceBook all under the same user: <span style={{ fontWeight: 600 }}>brainbulb.ai</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion" id="accordion7">
                                                <div className="accordion-item">
                                                    <h3 className="accordion-header" id="heading7">
                                                        <button className="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapse7"
                                                            aria-expanded="false" aria-controls="collapse7">
                                                            Can I track my order?
                                                        </button>
                                                    </h3>
                                                    <div id="collapse7" className="accordion-collapse collapse"
                                                        aria-labelledby="heading7" data-bs-parent="#accordion7">
                                                        <div className="accordion-body">
                                                            Yes we will send you an email after purchase that will include a tracking number for your order.  You’ll be able to use that to keep an eye on when your purchase ships and an estimated arrival time.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="tab-pane fade" id="v-pills-profile" role="tabpanel"
                                            aria-labelledby="v-pills-profile-tab" tabindex="0">
                                            <div className="accordion" id="accordion2">
                                                <div className="accordion-item">
                                                    <h3 className="accordion-header" id="heading2">
                                                        <button className="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapse2"
                                                            aria-expanded="false" aria-controls="collapse2">
                                                            For order and Payment
                                                        </button>
                                                    </h3>
                                                    <div id="collapse2" className="accordion-collapse collapse"
                                                        aria-labelledby="heading2" data-bs-parent="#accordion2">
                                                        <div className="accordion-body">
                                                            Answer 2
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-messages" role="tabpanel"
                                            aria-labelledby="v-pills-messages-tab" tabindex="0">
                                            <div className="accordion" id="accordion2">
                                                <div className="accordion-item">
                                                    <h3 className="accordion-header" id="heading2">
                                                        <button className="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapse2"
                                                            aria-expanded="false" aria-controls="collapse2">
                                                            For Payment & Credit
                                                        </button>
                                                    </h3>
                                                    <div id="collapse2" className="accordion-collapse collapse"
                                                        aria-labelledby="heading2" data-bs-parent="#accordion2">
                                                        <div className="accordion-body">
                                                            Answer 2
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-settings" role="tabpanel"
                                            aria-labelledby="v-pills-settings-tab" tabindex="0">
                                            <div className="accordion" id="accordion2">
                                                <div className="accordion-item">
                                                    <h3 className="accordion-header" id="heading2">
                                                        <button className="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapse2"
                                                            aria-expanded="false" aria-controls="collapse2">
                                                            For Shipping
                                                        </button>
                                                    </h3>
                                                    <div id="collapse2" className="accordion-collapse collapse"
                                                        aria-labelledby="heading2" data-bs-parent="#accordion2">
                                                        <div className="accordion-body">
                                                            Answer 2
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-5" role="tabpanel" aria-labelledby="v-pills-5-tab"
                                            tabindex="0">
                                            <div className="accordion" id="accordion2">
                                                <div className="accordion-item">
                                                    <h3 className="accordion-header" id="heading2">
                                                        <button className="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapse2"
                                                            aria-expanded="false" aria-controls="collapse2">
                                                            Licence & Invoice
                                                        </button>
                                                    </h3>
                                                    <div id="collapse2" className="accordion-collapse collapse"
                                                        aria-labelledby="heading2" data-bs-parent="#accordion2">
                                                        <div className="accordion-body">
                                                            Answer 2
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FAQ