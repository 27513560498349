import React from "react";
import MainLayout from "../layouts/MainLayout";
import CategoryBody from "../containers/Category";

const Category = () => {
  return (
    <MainLayout>
      <CategoryBody />
    </MainLayout>
  );
};

export default Category;
