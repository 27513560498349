import axios from 'axios';
import React, { useState } from 'react'
import { BASE_URL } from '../Adapters';
import "../styles/newsletter.css"
import { toast } from 'react-toastify';

const Email = () => {
    // states
    const [email, setEmail] = useState('')
    const [loader, setLoader] = useState(false)

    // validate email
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const subscribeNewletterAPI = async () => {
        if (validateEmail(email)) {
            setLoader(true)
            try {
                const response = await axios.post(
                    `${BASE_URL}product/api/subscribe/`,
                    {
                        email: email,
                    }
                );
                if (response?.status == 201) {
                    toast.success(response?.data?.message, { toastId: 'toast' })
                    setEmail("")
                }
                setLoader(false)
            } catch (error) {
                setLoader(false)
                if (error?.response?.status == 400) {
                    toast.error(error?.response?.data?.email[0], { toastId: 'toast' })
                }
            }
        } else {
            toast.error("Email is invalid.", { toastId: 'toast' })
        }
    };
    return (
        <>
            <div class="email_section" style={{ marginBottom: "171px" }}>
                <div class="container">
                    <div class="row justify-content-center align-items-center email_row">
                        <div class="col-lg-5">
                            <div class="email_content">
                                <div class="sale_text"><span>15% Up to </span>OFF ON all Products</div>
                                <h3>Stay home & get AI generated Images on your favourite products</h3>
                                <p>Start your customization with the help of AI</p>
                                <div class="email_input">
                                    <input type="text" placeholder="Enter your email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                    <button type='button' onClick={subscribeNewletterAPI} disabled={loader || !email}>Subscribe Now</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5"><img class="img-fluid" src={require('../assets/images/hero_img.png')} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Email