import React from 'react'
import MainLayout from '../layouts/MainLayout'
import Hero from '../containers/Hero'
import Email from '../containers/Email'
import FAQ from '../containers/FAQ'
import '../styles/index.css';

const Home = () => {
  return (
    <MainLayout>
      <Hero />
      <Email />
      <div id="faq-section">
        <FAQ />
      </div>
    </MainLayout>
  )
}

export default Home