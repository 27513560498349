import React, { forwardRef } from "react";

const LoadingModal = ({ loading }) => {
  return loading && (
    <div className="loading_popup" style={{ display: "block" }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body text-start">
            <h3 className="text-center">
              Your Imagination is Turning into Reality!
            </h3>
            <div className="loading_container text-center">
              <div className="loading"></div>
            </div>
            <h6>Please wait: it can take up to 2 minutes</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingModal;
