import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import NavBottom from "../components/NavBottom";
import Footer from "../components/Footer";

const MainLayout = ({ children, style }) => {
  const isLoginSignup =
    window.location.pathname === "/login" ||
    window.location.pathname === "/signup";

    useEffect(() => {
      if (document.querySelector('.topscroll')) {
        document.querySelector('.topscroll').scrollIntoView();
      }
    }, [])
  return (
    <div {...style} className="topscroll">
      {!isLoginSignup ? (
        <>
          <Navbar />
          <NavBottom />
          {children}
          <Footer />
        </>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

export default MainLayout;
