import {useRef} from "react";
import { PRINTFUL_PRODUCTS } from "../constants";
import "../styles/select_items.css";
import { Link } from "react-router-dom";

const SelectItems = () => {
  const buttonsRef = useRef([]);

  const handleClick = (index) => {
    buttonsRef.current.forEach((button, i) => {
      if (i === index) {
        button.classList.add("active");
      } else {
        button.classList.remove("active");
      }
    });
  };

  return (
    <div class="super_main">
      <div class="top_heading">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-auto">
              <h3>Item Selection</h3>
            </div>
            <div class="col-auto">
              <span>Home {">"} Item Selection</span>
            </div>
          </div>
        </div>
      </div>

      <div class="container-lg">
        {/* <!-- Section 3 --> */}
        <div class="item_selection">
          <h2 class="top_heading_name">Item Selection</h2>
          <div class="segmented-buttons">
            <button
              class="segmented-button"
              ref={(el) => (buttonsRef.current[0] = el)}
              onClick={() => handleClick(0)}
            >
              <span>
                <img src={require("../assets/icons/Frame.png")} alt="a" />
                Women
              </span>
            </button>
            <button
              class="segmented-button"
              ref={(el) => (buttonsRef.current[1] = el)}
              onClick={() => handleClick(1)}
            >
              <img src={require("../assets/icons/Frame1.png")} alt="a" />
              Man
            </button>
            <button
              class="segmented-button"
              ref={(el) => (buttonsRef.current[2] = el)}
              onClick={() => handleClick(2)}
            >
              <img src={require("../assets/icons/Frame2.png")} alt="a" />
              Kids
            </button>
            <button
              class="segmented-button"
              ref={(el) => (buttonsRef.current[3] = el)}
              onClick={() => handleClick(3)}
            >
              <img src={require("../assets/icons/Frame3.png")} alt="a" />
              Home
            </button>
            <button
              class="segmented-button"
              ref={(el) => (buttonsRef.current[4] = el)}
              onClick={() => handleClick(4)}
            >
              <img src={require("../assets/icons/hat.png")} alt="a" />
              Hats
            </button>
            <button
              class="segmented-button"
              ref={(el) => (buttonsRef.current[5] = el)}
              onClick={() => handleClick(5)}
            >
              <img src={require("../assets/icons/Frame4.png")} alt="a" />
              Jewelry
            </button>
          </div>

          <div class="dropdown_row filter_container">
            <div class="d-flex justify-content-between w-100">
              <div class="d-flex" style={{ flexWrap: "nowrap" }}>
                <div class="select_box">
                  <div class="select_con">
                    <img src={require("../assets/icons/money.png")} alt="a" />
                    <select
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option selected>Prices</option>
                      <option value="1">50$ - 100$</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
                <div class="select_box">
                  <div class="select_con">
                    <img
                      src={require("../assets/icons/category.png")}
                      alt="a"
                    />
                    <select
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option selected>Categories</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
                <div class="select_box">
                  <div class="select_con">
                    <img src={require("../assets/icons/color.png")} alt="a" />
                    <select
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option selected>Colors</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
                <div class="select_box">
                  <div class="select_con">
                    <img src={require("../assets/icons/size.png")} alt="a" />
                    <select
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option selected>Sizes</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
                <div class="select_box">
                  <button className="slect_box_button">
                    <img src={require("../assets/icons/sale.png")} alt="a" /> On
                    sale
                  </button>
                </div>
              </div>
              <div class="select_box">
                <div class=" select_con">
                  <img src={require("../assets/icons/sort.png")} alt="a" />
                  <select
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option selected>Sort order</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row card_products">
            {PRINTFUL_PRODUCTS.map((product) => {
              return (
                <div class="col-sm-6 col-md-3 product_card">
                  <div class="card" data-card-id={product.id}>
                    <div class="product_img">
                      <img
                        class="img-fluid"
                        src={product.thumbnail_url}
                        alt="a"
                      />
                    </div>

                    <div class="card_below_content">
                      <div class="row justify-content-center p_details">
                        <div class="col-auto">
                          <div class="title mt-4">{product.product_name}</div>
                        </div>
                      </div>
                      <Link to={`/product_details/${product.id}`}>
                        <button type="button" class="btn_for_card">
                          Customize
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* <!-- Section 3 --> */}
      </div>
    </div>
  );
};

export default SelectItems;
