import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { CloseButton, SnackbarUtilsConfiguration } from "./components/Alert";
import { SnackbarProvider } from "notistack";
import { createContext, useState } from "react";

import Home from "./pages/Home";
import ProductDetails from "./pages/ProductDetails";
import GenerateAI from "./pages/GenerateAI";
import Editor from "./pages/Editor";
import AboutUs from "./pages/AboutUs";
import Cart from "./pages/Cart";
import Payment from "./pages/Payment";
import ContactUs from "./pages/ContactUs";
import YourOrders from "./pages/YourOrders";
import SelectItems from "./pages/SelectItems";
import LimitedDesigns from "./pages/LimitedDesigns";
import FAQs from "./pages/FAQs";
import OrderConfirm from "./pages/OrderConfirm";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import CategoryPage from "./pages/Category";
import CheckoutForm from "./pages/CheckoutForm";
import GlobalSearch from "./pages/GlobalSearch";
import TermAndConditions from "./pages/TermsAndConditions";

export const AppContext = createContext(null);

function App() {
  const [aiGeneratedImages, setAIGeneratedImages] = useState([]);
  const [image, setImage] = useState(null);
  const [bgRemove, setBgRemove] = useState(null);
  const [revertBg, setRevertBg] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [addToCart, setAddToCart] = useState([]);
  const [productDetail, setProductDetail] = useState([]);
  const [selectedPrize, setSelectedPrize] = useState([]);
  const [selectedVariantId, setSelectedVariantId] = useState([]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
      />
      <AppContext.Provider
        value={{
          selectedVariantId,
          setSelectedVariantId,
          selectedPrize,
          setSelectedPrize,
          productDetail,
          setProductDetail,
          addToCart,
          setAddToCart,
          previewImage,
          setPreviewImage,
          selectedColor,
          setSelectedColor,
          selectedSize,
          setSelectedSize,
          aiGeneratedImages,
          categoryProducts,
          setCategoryProducts,
          setAIGeneratedImages,
          image,
          products,
          setProducts,
          categories,
          setCategories,
          setImage,
          bgRemove,
          setBgRemove,
          revertBg,
          setRevertBg,
          currentImage,
          setCurrentImage,
        }}
      >
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={2000}
          action={(key) => <CloseButton id={key} />}
          preventDuplicate={true}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <SnackbarUtilsConfiguration />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/category_details/:printfulCategoryId"
              element={<CategoryPage />}
            />
            <Route
              path="/product_details/:productId"
              element={<ProductDetails />}
            />
            <Route path="/check_page" element={<CheckoutForm />} />
            <Route path="/generate_ai_images" element={<GenerateAI />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/editor" element={<Editor />} />
            <Route path="/about_us" element={<AboutUs />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/contact_us" element={<ContactUs />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/your_orders" element={<YourOrders />} />
            <Route path="/select_items" element={<SelectItems />} />
            <Route path="/limited_designs" element={<LimitedDesigns />} />
            <Route path="/order_confirm" element={<OrderConfirm />} />
            <Route path="/search/:searchText" element={<GlobalSearch />} />
            <Route path="/terms_conditions" element={<TermAndConditions />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </SnackbarProvider>
      </AppContext.Provider>
    </>
  );
}

export default App;
