import React from "react";
import "../styles/about_us.css";

const AboutUs = () => {
  return (
    <>
      <div className="super_main">
        <div className="top_heading">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-auto">
                <h3>About us</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="container about_us_container">
          <h2 className="top_heading_name">About Us</h2>
          <div className="row about_us_row justify-content-center align-items-center">
            <div className="col-md-6">
              <div className="content">
                <h5 className="">About Us</h5>
                <p className="text-justify">
                  We’re a small team of creators with an idea.  What if, instead of merch and brands representing other people and companies, you could design something to represent you?  We want you to be able to express your own ideas and creativity and put it on anything.  A coffee mug, a shirt, or even a canvas.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid zoom-on-hover"
                src={require("../assets/images/hero_img.png")}
                alt="a"
              />
            </div>
            <p className="text-justify">
              You can do this through a powerful image generator integrated into our site.  Just by typing in a single prompt, you’ll be able to generate 4 unique images that don’t exist anywhere else.  Once you have the right image, start designing and you can further customize your creation!  You can choose from a list of fonts and type anything you want, or you can draw on it, put on filters, the list goes on.  Whatever the final product is, you decide.
            </p>
            <p className="text-justify">
              We are Brainbulb, a brand that you create.
            </p>
          </div>
          <div className="row team_container">
            <h3 className="term_heading">Contact Us</h3>
            <br />
            <p className="term_paragraph text-justify">
              If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:<br />
              • Email: <span className="email_text">support@brainbulb.ai</span><br />
              • Phone: <span className="email_text">+1 832-412-1122</span><br />
              • Address: <span className="email_text">17350 State Hwy 249, Ste 220 #18345, Houston, Texas 77064, USA</span>
            </p>
            <br /><br />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
