import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "../styles/nesteddropdown.css";
import axios from "axios";
import { AppContext } from "../App";
import { Alerts } from "./Alert";
import { BASE_URL } from "../Adapters";
const NestedDropdown = ({ category }) => {
  const navigate = useNavigate();
const location = useLocation()

  const { setCategoryProducts } = useContext(AppContext);

  const [subMenuBottom, setSubMenuBottom] = useState(false)

  useEffect(() => {
    setCategoryProducts()
    const script = document.createElement("script");
    script.src = "/scripts/nestedDropDown.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // set the height of Calendar based on the screen height
  useEffect(() => {
    const updateCalendarHeight = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 992) {
        setSubMenuBottom(true);
      }
    };

    // Call the function initially and add event listener for window resize
    updateCalendarHeight();
    window.addEventListener('resize', updateCalendarHeight);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateCalendarHeight);
    };
  }, []);

  const fetchAPI = async (categoryId) => {
    console.log(categoryId,"categoryId")
    try {
      const response = await axios.get(
        `${BASE_URL}product/api/category-products/${categoryId}/`
      );
      if (response.data.length) {
        setCategoryProducts([]);
        navigate(`/category_details/${categoryId}`);
        if(location.pathname.startsWith('/category_details/')){
          window.location.reload()
        }
        Alerts.success("Data Loaded Successfully");
      } else {
        Alerts.error("No Product Found In this Category");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="dropdown">
      <button
        className="btn btn-default dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded={"false"}
        onDoubleClick={async () =>
          await fetchAPI(category.printful_category_id)
        }
      >
        {category.category_name}
        <span className="caret"></span>
      </button>
      <ul className={`dropdown-menu ${subMenuBottom && "dropdown-menu-mobile-bottom"}`}>
        {category.sub_category.length > 0 &&
          category.sub_category.map((sub_cat) => (
            <li className="dropdown-submenu">
              {sub_cat.sub_sub_category.length == 0
                ?
                <Link
                  className="dropdown-item"
                  onClick={async () =>
                    await fetchAPI(sub_cat.printful_category_id)
                  }
                >
                  {sub_cat.sub_category_name}{" "}
                </Link>
                :
                <Link
                  className="dropdown-item test"
                  tabindex="-1"
                  to={"/"}
                  onDoubleClick={async () =>
                    await fetchAPI(sub_cat.printful_category_id)
                  }
                >
                  {sub_cat.sub_category_name}{" "}
                  {sub_cat.sub_sub_category.length > 0 && <>&#62;</>}
                </Link>
              }
              {sub_cat.sub_sub_category.length > 0 && (
                <ul className={`dropdown-menu ${subMenuBottom && "dropdown-menu-mobile-bottom"}`}>
                  {sub_cat.sub_sub_category.length > 0 &&
                    sub_cat.sub_sub_category.map((nestedCat) => (
                      <li>
                        <button
                          className="dropdown-item"
                          tabindex="-1"
                          onClick={async () => {
                            await fetchAPI(nestedCat.printful_category_id);
                          }}
                        >
                          {nestedCat.sub_sub_category_name}
                        </button>
                      </li>
                    ))}
                </ul>
              )}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default NestedDropdown;
