import React from "react";
import MainLayout from "../layouts/MainLayout";
import LimitedDesignsBody from "../containers/LimitedDesigns";

const LimitedDesigns = () => {
  return (
    <MainLayout>
      <LimitedDesignsBody />
    </MainLayout>
  );
};

export default LimitedDesigns;
