import React from "react";
import { ALL_PRODUCTS } from "../constants";
import "../styles/your_orders.css";

const YourOrders = () => {
  return (
    <>
      <div class="super_main" />

      <div class="top_heading">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-auto">
              <h3>Your Orders</h3>
            </div>
          </div>
        </div>
      </div>

      <div class="container main_container">
        <div class="row"></div>
        <div class="row">
          <div id="product-container">
            <div class="all_orders">
              <div class="order">
                <table class="table table-striped-columns mb-5">
                  <thead>
                    <tr>
                      <th scope="col">Printful Order ID</th>
                      <th scope="col">Status</th>
                      <th scope="col">Shipping</th>
                      <th scope="col">Recipent</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">order id</th>
                      <td>order.status</td>
                      <td>order.shipping</td>
                      <td>
                        order.recipient_name - order.address1 - order.state_code
                        - order.city - order.zip - order.email - order.phone
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-md-3"></div>
                  <div class="col-md-9">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Image</th>
                          <th scope="col">Item ID</th>
                          <th scope="col">Variant Id</th>
                          <th scope="col">Name</th>
                          <th scope="col">Retail Price</th>
                          <th scope="col">Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ALL_PRODUCTS.map((item) => {
                          return (
                            <tr>
                              <td>
                                <a href={item.preview_url}>
                                  <img
                                    src={item.preview}
                                    alt="a"
                                    height="100px"
                                    width="100px"
                                  />
                                </a>
                              </td>
                              <td>{item.id}</td>
                              <td>{item.varient_id}</td>
                              <td>{item.name}</td>
                              <td>{item.retail_price}</td>
                              <td>{item.quantity}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default YourOrders;
