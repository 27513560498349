import React from "react";
import "../styles/FAQs.css";

const FAQs = () => {
  return (
    <>
      <div class="super_main">
        <div class="top_heading">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col-auto">
                <h3>Frequently Asked Questions</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="container FAQs_container">
          <div class="question_heading">
            <h1>Have any Questions ?</h1>
            <p>You can ask anything you want to know about Feedback.</p>
          </div>

          <div class="row qc_row nav nav-tabs" id="nav-tab" role="tablist">
            <h2 class="top_heading_name">Payment</h2>
            {/* <!-- qc = Question Category --> */}
            <div
              class="col about_question"
              id="nav-home-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-home"
              type="button"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              <div class="content active">
                <img
                  class="img-fluid"
                  src={require("../assets/icons/order2.png")}
                  alt="a"
                />
                <br />
                <span>Order</span>
              </div>
            </div>
            <div
              class="col about_question"
              id="nav-profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-profile"
              type="button"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              <div class="content">
                <img
                  class="img-fluid"
                  src={require("../assets/icons/payment2.png")}
                  alt="a"
                />
                <br />
                <span>Payments</span>
              </div>
            </div>
            <div
              class="col about_question"
              id="nav-contact-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-contact"
              type="button"
              role="tab"
              aria-controls="nav-contact"
              aria-selected="false"
            >
              <div class="content">
                <img
                  class="img-fluid"
                  src={require("../assets/icons/delivery2.png")}
                  alt="a"
                />
                <br />
                <span>Delivery</span>
              </div>
            </div>
            <div
              class="col about_question"
              id="nav-disabled-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-disabled"
              type="button"
              role="tab"
              aria-controls="nav-disabled"
              aria-selected="false"
            >
              <div class="content">
                <img
                  class="img-fluid"
                  src={require("../assets/icons/return2.png")}
                  alt="a"
                />
                <br />
                <span>Returns</span>
              </div>
            </div>
          </div>
          <div class="row justify-content-center accordian_row">
            <div class="col-md-9">
              <div class="general_heading">
                <h1>General Questions</h1>
              </div>
              {/* <!-- Accordian --> */}
              <div class="tab-content" id="nav-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                  tabindex="0"
                >
                  <div class="accordion" id="accordionPanelsStayOpenExample">
                    <div class="accordion-item">
                      <h2
                        class="accordion-header"
                        id="panelsStayOpen-headingOne"
                      >
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="true"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          What are FAQ questions?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        class="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div class="accordion-body">
                          An FAQ page{" "}
                          <span>
                            (short for Frequently Asked Question page)
                          </span>{" "}
                          is a part of your website that provides answers to
                          common questions, assuages concerns, and overcomes
                          objections. It's a space where customers away from
                          your sales-focused landing pages and homepage.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2
                        class="accordion-header"
                        id="panelsStayOpen-headingTwo"
                      >
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          What is FAQ process?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        class="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div class="accordion-body">
                          <strong>
                            This is the second item's accordion body.
                          </strong>{" "}
                          It is hidden by default, until the collapse plugin
                          adds the appropriate classes that we use to style each
                          element. These classes control the overall appearance,
                          as well as the showing and hiding via CSS transitions.
                          You can modify any of this with custom CSS or
                          overriding our default variables. It's also worth
                          noting that just about any HTML can go within the
                          <code>.accordion-body</code>, though the transition
                          does limit overflow.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2
                        class="accordion-header"
                        id="panelsStayOpen-headingThree"
                      >
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          What is the purpose of FAQ?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThree"
                        class="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div class="accordion-body">
                          <strong>
                            This is the third item's accordion body.
                          </strong>{" "}
                          It is hidden by default, until the collapse plugin
                          adds the appropriate classes that we use to style each
                          element. These classes control the overall appearance,
                          as well as the showing and hiding via CSS transitions.
                          You can modify any of this with custom CSS or
                          overriding our default variables. It's also worth
                          noting that just about any HTML can go within the
                          <code>.accordion-body</code>, though the transition
                          does limit overflow.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2
                        class="accordion-header"
                        id="panelsStayOpen-headingfour"
                      >
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFour"
                        >
                          What is an online FAQ?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFour"
                        class="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingfour"
                      >
                        <div class="accordion-body">
                          <strong>
                            This is the third item's accordion body.
                          </strong>{" "}
                          It is hidden by default, until the collapse plugin
                          adds the appropriate classes that we use to style each
                          element. These classes control the overall appearance,
                          as well as the showing and hiding via CSS transitions.
                          You can modify any of this with custom CSS or
                          overriding our default variables. It's also worth
                          noting that just about any HTML can go within the
                          <code>.accordion-body</code>, though the transition
                          does limit overflow.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                  tabindex="0"
                >
                  <div class="accordion" id="accordionPanelsStayOpenExample">
                    <div class="accordion-item">
                      <h2
                        class="accordion-header"
                        id="panelsStayOpen-headingOne"
                      >
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="true"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          What are FAQ questions?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        class="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div class="accordion-body">
                          An FAQ page{" "}
                          <span>
                            (short for Frequently Asked Question page)
                          </span>{" "}
                          is a part of your website that provides answers to
                          common questions, assuages concerns, and overcomes
                          objections. It's a space where customers away from
                          your sales-focused landing pages and homepage.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="nav-contact"
                  role="tabpanel"
                  aria-labelledby="nav-contact-tab"
                  tabindex="0"
                >
                  <div class="accordion" id="accordionPanelsStayOpenExample">
                    <div class="accordion-item">
                      <h2
                        class="accordion-header"
                        id="panelsStayOpen-headingOne"
                      >
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="true"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          What are FAQ questions?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        class="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div class="accordion-body">
                          An FAQ page{" "}
                          <span>
                            (short for Frequently Asked Question page)
                          </span>{" "}
                          is a part of your website that provides answers to
                          common questions, assuages concerns, and overcomes
                          objections. It's a space where customers away from
                          your sales-focused landing pages and homepage.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="nav-disabled"
                  role="tabpanel"
                  aria-labelledby="nav-disabled-tab"
                  tabindex="0"
                >
                  <div class="accordion" id="accordionPanelsStayOpenExample">
                    <div class="accordion-item">
                      <h2
                        class="accordion-header"
                        id="panelsStayOpen-headingOne"
                      >
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="true"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          What are FAQ questions?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        class="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div class="accordion-body">
                          An FAQ page{" "}
                          <span>
                            (short for Frequently Asked Question page)
                          </span>{" "}
                          is a part of your website that provides answers to
                          common questions, assuages concerns, and overcomes
                          objections. It's a space where customers away from
                          your sales-focused landing pages and homepage.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Accordian --> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQs;
