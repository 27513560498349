import React from "react";
import "../styles/terms_conditions.css";

const TermsAndConditions = () => {
    return (
        <>
            <div className="super_main">
                <div className="top_heading">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-auto">
                                <h3>Terms And Conditions</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container about_us_container">
                    <div className="row about_us_row justify-content-center align-items-center">
                        <div className="col-md-6">
                            <div className="content">
                                <h5 className="">Terms And Conditions</h5>
                                <p className="text-justify">
                                    Welcome to Brainbulb LLC. These terms and conditions outline the rules and regulations for the use of Brainbulb LLC’s Website, located at www.brainbulb.ai.
                                    <br /><br />
                                    By accessing this website we assume you accept these terms and conditions. Do not continue to use Brainbulb LLC if you do not agree to take all of the terms and conditions stated on this page.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img
                                className="img-fluid zoom-on-hover"
                                src={require("../assets/images/hero_img.png")}
                                alt="a"
                            />
                        </div>
                    </div>
                    <div>
                        <h3 className="term_heading">1. Company Details</h3>
                        <p className="term_paragraph text-justify">
                            • Registered Company Name: Brainbulb LLC<br />
                            • Address: 17350 State Hwy 249, Ste 220 #18345, Houston, Texas 77064, USA<br />
                            • Contact Phone Number: +1 832-412-1122<br />
                            • Email: <span className="email_text">support@brainbulb.ai</span>
                        </p>
                        <h3 className="term_heading">2. Product Information</h3>
                        <p className="term_paragraph text-justify">
                            • Return and Exchange Policy: Since each product is unique, we only offer returns if the item is faulty or incorrect. To initiate a return, send an email to brainbulbinfo@gmail.com with a picture of your product and your order number. We will respond with further instructions. All returns must be initiated within 4 weeks after the item has been received.<br />
                            • Shipping Policy: We do not ship internationally. For domestic shipping, users will be provided with tracking information. You have several shipping options to choose from - they can range from within 1-8 business days.<br />
                            • Payment Methods: All payments are processed through Stripe.<br />
                            • Order Processing Time: Order processing time can vary, but 95% of our orders are processed and shipped within 3-5 days.
                        </p>
                        <h3 className="term_heading">3. User Conduct</h3>
                        <p className="term_paragraph text-justify">
                            • Account Responsibilities: Users are responsible for maintaining the confidentiality of their account and password and for restricting access to their computer. Users agree to accept responsibility for all activities that occur under their account or password.<br />
                            • Prohibited Activities: Users may not misuse our website. Users are prohibited from engaging in or attempting to engage in any of the following activities: hacking, transmitting viruses, malware, or any other type of malicious code, or using the website for any illegal purposes.<br />
                            • Intellectual Property Rights: All content on this site, including text, graphics, logos, images, and software, is the property of Brainbulb LLC and is protected by international copyright laws.
                        </p>
                        <h3 className="term_heading">4. Liability</h3>
                        <p className="term_paragraph text-justify">
                            • Disclaimers of Warranties: This site is provided by Brainbulb LLC on an “as is” and “as available” basis. We do not offer warranties of any kind, express or implied, as to the operation of the site or the information, content, materials, or products included on this site.<br />
                            • Limitation of Liability: To the fullest extent permissible by applicable law, Brainbulb LLC disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose. Brainbulb LLC will not be liable for any damages of any kind arising from the use of this site, including, but not limited to, direct, indirect, incidental, punitive, and consequential damages.<br />
                            • Indemnification: Users agree to indemnify, defend, and hold harmless Brainbulb LLC, its officers, directors, employees, agents, licensors, and suppliers from and against all losses, expenses, damages, and costs, including reasonable attorneys’ fees, resulting from any violation of these terms and conditions or any activity related to users’ account (including negligent or wrongful conduct) by users or any other person accessing the site using users’ account.
                        </p>
                        <h3 className="term_heading">5. Privacy Policy</h3>
                        <p className="term_paragraph text-justify">
                            • Data Collection and Usage: For detailed information on how we collect, use, and protect users’ personal information, please refer to our Privacy Policy.<br />
                            • Cookies and Tracking Technologies: Our website may use cookies and similar tracking technologies to enhance user experience. By using our website, users consent to our use of cookies.<br />
                            • User Rights and Data Protection: Users have the right to access, correct, or delete their personal information. For any privacy-related concerns, users can contact us at <span className="email_text">support@brainbulb.ai</span>.<br /><br />

                            Privacy Policy

                            Effective Date: January 1, 2024
                            <br /><br />
                            At Brainbulb LLC, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.brainbulb.ai or make a purchase from us. Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it.
                            <br /><br />
                            <strong>1. Information We Collect</strong>
                            <br />
                            • Personal Data: When you make a purchase, we collect personal information necessary to fulfill your order, including your name, shipping address, email address, and phone number.<br />
                            • Newsletter Sign-Up: If you sign up for our newsletter, we collect your email address to send you updates and promotional offers.
                            <br /><br />
                            <strong>2. Use of Your Information</strong>
                            <br />
                            • Order Fulfillment: We use your personal data to process and deliver your orders.<br />
                            • Customer Service: We use your information to provide customer support, respond to inquiries, and address any issues you may have.<br />
                            • Marketing: If you have opted in, we use your email address to send you newsletters and promotional materials.
                            <br /><br />
                            <strong>3. Data Security</strong>
                            <br />
                            We implement appropriate technical and organizational measures to protect your personal data from unauthorized access, loss, misuse, or alteration. These measures include:<br />
                            • Secure server hosting.<br />
                            • Encryption of sensitive data.<br />
                            • Regular security audits.
                            <br /><br />
                            <strong>4. Sharing Your Information</strong>
                            <br />
                            We do not sell or rent your personal data to third parties. We may share your data with third-party service providers who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.
                            <br /><br />
                            <strong>5. Your Rights</strong>
                            <br />
                            • Access and Correction: You have the right to access the personal data we hold about you and to request corrections if any information is inaccurate or incomplete.<br />
                            • Deletion: You may request the deletion of your personal data, subject to certain legal exceptions.<br />
                            • Opt-Out: You can opt out of receiving our marketing emails at any time by following the unsubscribe link included in every email or by contacting us directly at <span className="email_text">support@brainbulb.ai</span>.
                            <br /><br />
                            <strong>6. Use of Cookies</strong>
                            <br />
                            We use cookies and similar tracking technologies to enhance your browsing experience on our website. Cookies are small data files stored on your device that help us understand how you use our site and improve its functionality. You can manage your cookie preferences through your browser settings.
                            <br /><br />
                            <strong>7. Children’s Privacy</strong>
                            <br />
                            Our website is not intended for children under 13 years of age. We do not knowingly collect personal data from children under 13. If we become aware that we have inadvertently received personal data from a child under 13, we will delete such information from our records.
                            <br /><br />
                            <strong>8. Changes to This Privacy Policy</strong>
                            <br />
                            We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. The updated policy will be posted on this page with the effective date.
                            {/* <br /><br /> 
                            <strong>9. Contact Us</strong>
                            <br />
                            If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:<br />
                            • Email: <span className="email_text">support@brainbulb.ai</span><br />
                            • Phone: +1 832-412-1122<br />
                            • Address: 17350 State Hwy 249, Ste 220 #18345, Houston, Texas 77064, USA */}
                            <br /><br />
                            By using our website, you consent to our collection and use of your personal data as described in this Privacy Policy.
                        </p>
                        <h3 className="term_heading">6. Dispute Resolution</h3>
                        <p className="term_paragraph text-justify">
                            • Governing Law: These terms and conditions are governed by and construed in accordance with the laws of the State of Texas, USA, and users irrevocably submit to the exclusive jurisdiction of the courts in that State or location.<br />
                            • Arbitration: Any disputes arising out of or relating to these terms and conditions will be resolved through confidential binding arbitration in Houston, Texas, USA, and users waive their right to participate in a class action lawsuit or class-wide arbitration.
                        </p>
                        <h3 className="term_heading">7. Miscellaneous</h3>
                        <p className="term_paragraph text-justify">
                            • Links to Third-Party Websites: Our website may contain links to third-party websites. Brainbulb LLC is not responsible for the content or practices of these websites and users access them at their own risk.<br />
                            • Termination of Services: Brainbulb LLC reserves the right to terminate or suspend access to our website and services without prior notice or liability, for any reason whatsoever, including without limitation if users breach the terms.<br />
                            • Entire Agreement: These terms and conditions, together with our Privacy Policy, constitute the entire agreement between users and Brainbulb LLC regarding the use of our website and services, superseding any prior agreements.
                        </p>
                        <p className="term_paragraph text-justify">
                            By using our website, users agree to these terms and conditions. If users have any questions or concerns, they can contact us at <span className="email_text">support@brainbulb.ai</span>.
                        </p>
                        <p className="term_paragraph text-justify">Effective Date: January 1, 2024</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TermsAndConditions;
