import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../App";
import { Alerts } from "../components/Alert";
import axios from "axios";
import { BASE_URL } from "../Adapters";

const Category = () => {
  const navigate = useNavigate()
  const { printfulCategoryId } = useParams();

  const [categoryProducts, setCategoryProducts] = useState([AppContext]);
  const [loader, setLoader] = useState(true)

  const fetchAPI = async () => {
    setLoader(true)
    try {
      const response = await axios.get(
        `${BASE_URL}product/api/category-products/${printfulCategoryId}/`
      );
      if (response.data.length) {
        setCategoryProducts(response.data);
        setLoader(false)
        Alerts.success("Data Loaded Successfully");
      } else {
        setCategoryProducts([])
        setLoader(false)
        Alerts.error("No Product Found In this Category");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (document.querySelector('.topscroll')) {
      document.querySelector('.topscroll').scrollIntoView();
    }
  }, [])

  useEffect(() => {
    fetchAPI()
  }, [])
  return (
    <>
      <div className="topscroll item_selection">
        <h2 style={{ marginTop: "2%" }}>Category Details</h2>
        {loader ? <div>Data is loading...</div>
          :
          <>
            {categoryProducts?.length > 0 ? (
              <div className="row card_products" style={{ marginTop: "5%" }}>
                {categoryProducts.map((categoryProduct) => (
                  <div className="col-sm-6 col-md-3 product_card">
                    <div className="card" data-card-id={categoryProduct.product_id}>
                      <div className="product_img">
                        <img
                          className="img-fluid"
                          src={categoryProduct.thumbnail_url}
                          alt={categoryProduct.product_id}
                          loading="lazy"
                        />
                      </div>

                      <div className="card_below_content">
                        <div className="row justify-content-center p_details">
                          <div className="col-auto">
                            <div className="title mt-4">
                              {categoryProduct.product_name}
                            </div>
                          </div>
                        </div>
                        <Link
                          to={{
                            pathname: `/product_details/${categoryProduct.product_id}`,
                          }}
                        >
                          <button type="button" className="btn_for_card">
                            Customize
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <>
                <div style={{ marginTop: "5%", marginLeft: "5%" }}>
                  No data found
                </div>
                {/* <div className="col-sm-6 col-md-3 product_card">
                  <div className="card" data-card-id={"1"}>
                    <div className="product_img">
                      <img
                        className="img-fluid"
                        src={require("../assets/images/T Shirt.png")}
                        alt={"categoryProduct.modal"}
                      />
                    </div>

                    <div className="card_below_content">
                      <div className="row justify-content-center p_details">
                        <div className="col-auto">
                          <div className="title mt-4">Name</div>
                        </div>
                      </div>
                      <Link to={`/product_details/1`}>
                        <button type="button" className="btn_for_card">
                          Customize
                        </button>
                      </Link>
                    </div>
                  </div>
                </div> */}
              </>
            )}
          </>
        }
      </div>
    </>
  );
};

export default Category;
