import React from "react";
import MainLayout from "../layouts/MainLayout";
import FAQsBody from "../containers/FAQs";

const FAQs = () => {
  return (
    <MainLayout>
      <FAQsBody />
    </MainLayout>
  );
};

export default FAQs;
