import React from "react";
import MainLayout from "../layouts/MainLayout";
import OrderConfirmBody from "../containers/OrderConfirm";

const OrderConfirm = () => {
  return (
    <MainLayout>
      <OrderConfirmBody />
    </MainLayout>
  );
};

export default OrderConfirm;
