import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "../styles/payment.css";
import axios from "axios";
import { AppContext } from "../App";
import { useNavigate } from "react-router-dom";
import RadioButton from "../components/RadioButton";
import Popup from "../components/Popup";
import StripePaymentWrapper from "./CheckoutForm";
import SimplePopup from "../components/SimplePopup";
import { BASE_URL } from "../Adapters";
import { toast } from "react-toastify";

let defaultValues = {
  fname: "",
  lname: "",
  address1: "",
  city: "",
  country_code: "US",
  email: "",
  number: "",
  state_code: "",
  zip: ''
}
const Payment = () => {
  const { addToCart } = useContext(AppContext);
  const navigate = useNavigate();
  const formRef = useRef(null);

  const userData = JSON.parse(localStorage.getItem('userData'))

  // states
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("US");
  const [selectedStateCode, setSelectedStateCode] = useState("");
  const [shippingDetails, setShippingDetails] = useState(null);
  const [cartTotalPrice, setCartTotalPrice] = useState(0)
  // const tax = parseFloat((cartTotalPrice * (10 / 100)).toFixed(1));
  const [shippingFormValues, setShippingFormValues] = useState(defaultValues)
  const [calculateShippingDisable, setCalculateShippingDisable] = useState(true)
  const [allShippingDetails, setAllShippingDetails] = useState(null)
  const [showCheckPage, setShowCheckPage] = useState(false)
  const [promoCode, setPromoCode] = useState('')
  const [promoLoader, setPromoLoader] = useState(false)
  const [updatedCartTotal, setUpdateCartTotal] = useState(0)
  const [promoDiscount, setPromoDiscount] = useState()
  const [stripeTax, setStripeTax] = useState(null)

  useEffect(() => {
    // total price calculation here with shipping and discount
    let price = +(+cartTotalPrice + (shippingDetails?.rate ? +shippingDetails?.rate : 0) + (stripeTax ? +stripeTax : 0) - (promoDiscount ? +promoDiscount : 0))?.toFixed(2)
    setUpdateCartTotal(price)
  }, [cartTotalPrice, shippingDetails, promoDiscount, stripeTax])

  useEffect(() => {
    // total price calculation here
    const totalPrice = addToCart?.reduce((accumulator, itm) => accumulator + (itm?.total_price ? +itm?.total_price : +itm?.retail_price), 0);
    setCartTotalPrice(totalPrice)
  }, [addToCart])



  useEffect(() => {
    // disable conditions for shipping calculate button
    if (!shippingFormValues?.fname ||
      !shippingFormValues?.lname ||
      !shippingFormValues?.address1 ||
      !shippingFormValues?.city ||
      !shippingFormValues?.country_code ||
      !shippingFormValues?.email ||
      !shippingFormValues?.number ||
      // !shippingFormValues?.state_code ||
      !shippingFormValues?.zip) {
      setCalculateShippingDisable(true)
    } else {
      setCalculateShippingDisable(false)
    }
  }, [shippingFormValues])

  useEffect(() => {
    // fetch all countries data
    fetchAPI();
  }, []);

  // check promo code
  const handleCheckPromoCode = async () => {
    if (userData?.token) {
      setPromoLoader(true)
      try {
        const headers = {
          headers: {
            'authorization': `Bearer ${userData?.token ? userData?.token : ""}`
          }
        }
        const response = await axios.post(
          `${BASE_URL}product/coupons/apply/`,
          {
            code: promoCode,
          }, headers
        );
        setPromoLoader(false)
        if (response?.status == 200) {
          let data = response?.data
          let discountedPrice
          if (data?.discount_percentage) {
            discountedPrice = updatedCartTotal * +data?.discount_percentage / 100
          } else {
            discountedPrice = +data?.discount_amount
          }
          if (discountedPrice <= updatedCartTotal) {
            setPromoDiscount(discountedPrice)
          } else {
            setPromoDiscount(updatedCartTotal)
          }
        }
      } catch (error) {
        if (error?.response?.status == 400) {
          toast.error(error?.response?.data?.error, { toastId: 'toast' })
        }
        setPromoLoader(false)
      }
    } else {
      toast.error('Login first to avail promo discount.', { toastId: 'toast' })
    }
  };

  // shipping option change
  const handleOptionSelect = (option) => {
    setShippingDetails(option);
  };

  // handle change of form
  const handleChange = (e) => {
    const { name, value } = e.target
    setShippingFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

  }

  const handleSubmit = async () => {
    try {
      // event.preventDefault();
      // const data = new FormData(event.currentTarget);
      // const values = Object.fromEntries(data.entries());
      const values = shippingFormValues;
      const payload = {
        recipient: {
          name: `${values?.fname} ${values?.lname}`,
          email: values?.email,
          phone: values?.number,
          address1: values.address1,
          city: values.city,
          country_code: values.country_code,
          state_code: values.state_code,
          zip: values.zip,
        },
        items: addToCart?.map((product) => ({
          quantity: product.quantity,
          total_price: product.total_price,
          retail_price: product.retail_price,
          variant_id: product.id,
          name: product?.product_name,
          files: [
            {
              type: "default",
              url: product?.mock_generated_image ? product?.mock_generated_image : product?.image_url,
              image_url: product?.image_url,
              preview: product?.preview,
            },
          ],
        })),
        packing_slip: {
          email: "john.doe@printful.com",
          phone: "288-888-8888",
          message: "Custom packing slip",
          logo_url: "https://i.picsum.photos/id/817/2000/2000.jpg",
        },
        total_price: updatedCartTotal,
        stripe_tax: stripeTax
      };

      const response = await axios.post(
        `${BASE_URL}product/api/orders`,
        payload
      );
      // navigate('/check_page');
      if (response?.status == 200) {
        setShippingFormValues({
          ...shippingFormValues,
          printful_order_id: response?.data?.result?.id
        })
        setShowCheckPage(true)
      }
      // console.log(payload);
    } catch (error) {
      if (error?.response?.status == 400) {
        toast.error(error?.response?.data?.error?.message?.split(":")[1], { toastId: 'toast' })
      }
      console.log(error);
    }
  };

  // submit checkout
  // const handleSubmit = async () => {
  //   // setShowCheckPage(true)
  //   // try {
  //   //   // event.preventDefault();
  //   //   // const data = new FormData(event.currentTarget);
  //   //   // const values = Object.fromEntries(data.entries());
  //   //   const values = shippingFormValues;
  //   //   const payload = {
  //   //     recipient: {
  //   //       address1: values.address1,
  //   //       city: values.city,
  //   //       country_code: values.country_code,
  //   //       state_code: values.state_code,
  //   //       zip: values.zip,
  //   //     },
  //   //     items: addToCart?.map((product) => ({
  //   //       quantity: product.quantity,
  //   //       total_price: product.total_price,
  //   //       retail_price: product.retail_price,
  //   //       variant_id: product.id,
  //   //       files: [
  //   //         {
  //   //           type: "default",
  //   //           url: product.preview,
  //   //         },
  //   //       ],
  //   //     })),
  //   //     packing_slip: {
  //   //       email: "john.doe@printful.com",
  //   //       phone: "288-888-8888",
  //   //       message: "Custom packing slip",
  //   //       logo_url: "https://i.picsum.photos/id/817/2000/2000.jpg",
  //   //     },
  //   //   };

  //   //   const response = await axios.post(
  //   //     `http://3.139.100.139/v1/product/api/orders`,
  //   //     payload
  //   //   );
  //   //   // navigate('/check_page');
  //   //   setShowCheckPage(true)
  //   //   console.log({ response });
  //   // } catch (error) {
  //   //   console.log(error);
  //   // }
  // };

  const fetchAPI = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}product/api/countries`
      );
      setCountries(response.data.result);
    } catch (error) {
      // console.log(error);
    }
  };

  // on change of country change states options data
  const handleCountryChange = useCallback((event) => {
    setSelectedCountryCode(event.target.value);
    setShippingFormValues((prev) => ({
      ...prev,
      country_code: event.target.value,
    }));
    const availableStates = countries.find(
      (rec) => rec.code === event.target.value
    );
    if (
      availableStates &&
      availableStates.states &&
      availableStates.states.length
    ) {
      setStates(availableStates.states);
    }
  },
    [countries]);

  useEffect(() => {
    const availableStates = countries?.find(
      (rec) => rec.code === selectedCountryCode
    );
    if (
      availableStates &&
      availableStates.states &&
      availableStates.states.length
    ) {
      setStates(availableStates.states);
    }
  }, [countries, selectedCountryCode])

  // handle state change
  const handleStateChange = (event) => {
    setSelectedStateCode(event.target.value);
    setShippingFormValues((prev) => ({
      ...prev,
      state_code: event.target.value,
    }));
  };

  // submit function to calculate shipping charges
  const handleShippingCharges = async () => {
    try {
      // const data = new FormData(formRef.current);
      // const values = Object.fromEntries(data.entries());
      const values = shippingFormValues;
      const payload = {
        cartTotalPrice: cartTotalPrice,
        recipient: {
          address1: values.address1,
          city: values.city,
          country_code: values.country_code,
          state_code: values.state_code,
          zip: values.zip,
        },
        items: addToCart.map((product) => ({
          quantity: product.quantity,
          variant_id: product.id,
        })),
      };
      const response = await axios.post(
        `${BASE_URL}product/api/shipping/rates`,
        payload
      );
      setAllShippingDetails(response.data.result)
      setShippingDetails(response.data.result[0]);
    } catch (error) {
      // console.log(error);
    }
  };

  // submit function to calculate stripe tax
  const handleStripeTaxCalculation = async () => {
    try {
      // const data = new FormData(formRef.current);
      // const values = Object.fromEntries(data.entries());
      const values = shippingFormValues;
      const payload = {
        city: values.city,
        country: values.country_code,
        state: values.state_code,
        postal_code: values.zip,
        total_amount: cartTotalPrice
      };
      const response = await axios.post(
        `${BASE_URL}product/api/calculate-tax/`,
        payload
      );
      console.log(response, "response")
      setStripeTax(response?.data?.tax_amount)
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <>
      <div className="super_main topscroll">
        <div className="top_heading">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-auto">
                <h3>Payment</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="container payment">
          <h2 className="top_heading_name">Payment</h2>
          <div
            className="row justify-content-lg-between justify-content-center"
            style={{ marginBottom: "150px" }}
          >
            <div className="col-lg-7 right-side">
              <div
                className="row nav nav-tabs payment_methods"
                id="myTab"
                role="tablist"
              >
                <div className="col">
                  <button
                    className="active"
                    id="paypal-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#paypal-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="paypal-tab-pane"
                  >
                    Stripe
                  </button>
                </div>
              </div>
              {/* <!-- navs and tabs --> */}
              <div className="row">
                <div className="tab-content payment_tabs" id="myTabContent">
                  <div
                  // className="tab-pane fade show active"
                  // aria-selected="true"
                  // id="paypal-tab-pane"
                  // role="tabpanel"
                  // aria-labelledby="paypal-tab"
                  // tabindex="0"
                  // onSubmit={handleSubmit}
                  // ref={formRef}
                  >
                    <div className="row row1">
                      <div className="col-12">
                        <span>First Name</span>
                      </div>
                      <div className="col-12">
                        <input
                          id="fname"
                          name="fname"
                          required
                          type="text"
                          placeholder="Enter Name"
                          value={shippingFormValues?.fname}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row row1">
                      <div className="col-6">
                        <span>Last Name</span>
                        <input
                          id="lname"
                          name="lname"
                          required
                          type="text"
                          placeholder="Enter Last Name"
                          value={shippingFormValues?.lname}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-6">
                        <span>Email Address</span>
                        <input
                          id="email"
                          name="email"
                          required
                          type="email"
                          placeholder="Enter Email Address"
                          value={shippingFormValues?.email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row row1">
                      <div className="col-6">
                        <span>Contact Number</span>
                        <input
                          id="number"
                          name="number"
                          required
                          type="text"
                          placeholder="Enter Last Name"
                          value={shippingFormValues?.number}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-6">
                        <span>Address</span>
                        <input
                          id="address1"
                          required
                          type="text"
                          name="address1"
                          placeholder="Address"
                          value={shippingFormValues?.address1}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row row1">
                      <div className="col-6">
                        <span>City</span>
                        <input
                          id="city"
                          name="city"
                          readonly
                          required
                          type="text"
                          placeholder="City"
                          value={shippingFormValues?.city}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-6">
                        <span>Country Code</span>

                        <div>
                          <select
                            className="form-select cursor-not-allowed"
                            id="country_code"
                            name="country_code"
                            value={selectedCountryCode}
                            onChange={handleCountryChange}
                            disabled={true}
                          >
                            <option value="">Select your Country</option>
                            {countries.map((country) => (
                              <option key={country.code} value={country.code}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row row1">
                      <div className="col-6">
                        <span>State Code (Select State)</span>
                        <div>
                          <select
                            className="form-select"
                            value={selectedStateCode}
                            onChange={handleStateChange}
                            id="state_code"
                            name="state_code"
                          >
                            <option value="">Select your State</option>
                            {states ? (
                              states.map((state) => {
                                return (
                                  <option
                                    key={state.code}
                                    value={state?.code}
                                  >
                                    {state.name}
                                  </option>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-6">
                        <span>Zip Code</span>
                        <input
                          id="zip"
                          name="zip"
                          type="text"
                          placeholder="Zip"
                          value={shippingFormValues?.zip}
                          onChange={handleChange}
                        />
                        <input
                          id="shipping_method_id"
                          name="shipping_method_id"
                          type="hidden"
                        />
                      </div>
                    </div>

                    <div
                      className="login_paypal_btn mt-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {shippingDetails &&
                        <button id="checkout-button" type="submit" onClick={() => { handleSubmit() }}>
                          Checkout
                        </button>
                      }
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- navs and tabs --> */}
            </div>
            <div className="col-sm-11 col-lg-4">
              <div className="cart_card right_cards">
                <h5>Have a promo code ?</h5>
                <div className="promo_code">
                  <input type="text" value={promoCode} onChange={(e) => { setPromoCode(e.target.value) }} />
                  <button type="button" disabled={promoLoader || !promoCode} onClick={handleCheckPromoCode}>Apply</button>
                </div>
              </div>
              <div className="cart_card right_cards">
                <h5 className="text-start">Order Summary</h5>
                <div className="order_summary">
                  <div className="row justify-content-between summary">
                    <div className="col-auto">Sub Total :</div>
                    <div className="col-auto">{cartTotalPrice} $</div>
                  </div>
                  <hr />
                  {allShippingDetails?.length > 0 &&
                    <div>
                      <div className="col-auto">Select a shipping Type</div>
                      <RadioButton
                        options={allShippingDetails?.map((itm, ind) => ({
                          ...itm,
                          name: itm?.name,
                          value: itm?.rate
                        }))}
                        onSelect={handleOptionSelect}
                        selectedValue={shippingDetails?.rate}
                      />
                    </div>
                  }
                  {shippingDetails && (
                    <>
                      <div className="row justify-content-between summary">
                        <div className="col-auto">Shipping Charge :</div>
                        <div className="col-auto" id="selected_shipping_price">
                          {shippingDetails?.rate} $
                        </div>

                      </div>
                      <div className="row justify-content-between summary">
                        <div className="col-auto">Expected Delivery Date:</div>
                        <div className="col-auto" id="selected_shipping_price">
                          {shippingDetails?.minDeliveryDate}
                        </div>
                      </div>
                      <hr />
                    </>
                  )}
                  {stripeTax &&
                    <div className="row justify-content-between summary">
                      <div className="col-auto">Sales Tax :</div>
                      <div className="col-auto" id="selected_shipping_price">
                        {stripeTax} $
                      </div>
                    </div>
                  }
                  {promoDiscount && (
                    <>
                      <div className="row justify-content-between summary">
                        <div className="col-auto">Promo Discount :</div>
                        <div className="col-auto" id="selected_shipping_price">
                          {promoDiscount} $
                        </div>
                      </div>
                      <hr />
                    </>
                  )}
                  <div className="row justify-content-between summary">
                    <div className="col-auto">Total :</div>
                    <div className="col-auto">{updatedCartTotal} $</div>
                  </div>
                </div>
              </div>
              <div className="shipping_charge_heading">
                <h5
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                    lineHeight: "20px",
                  }}
                >
                  Calculate Your Shipping Charges According to your Address
                </h5>
              </div>
              <div className="login_paypal_btn mt-3 text-center">
                <button
                  id="shipping_charge"
                  type="button"
                  onClick={() => {
                    handleShippingCharges()
                    handleStripeTaxCalculation()
                  }}
                  disabled={calculateShippingDisable}
                >
                  Calculate
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showCheckPage &&
        <SimplePopup
        >
          <StripePaymentWrapper
            setShowCheckPage={setShowCheckPage}
            shippingFormValues={shippingFormValues}
            cartTotalPrice={updatedCartTotal}
            stripeTax={stripeTax}
          />
        </SimplePopup>}
    </>
  );
};

export default Payment;
