export const ALL_PRODUCTS = [
  {
    id: 1,
    varient_id: 'sdfghjk',
    preview: "https://source.unsplash.com/user/c_v_r/100x100",
    varient_name: "Random Name",
    color: "red",
    size: "small",
    retail_price: "100",
    quantity: 20,
  },
  {
    id: 2,
    varient_id: 'rtyuiop',
    preview: "https://source.unsplash.com/user/c_v_r/100x100",
    varient_name: "Guess Name",
    color: "blue",
    size: "large",
    retail_price: "200",
    quantity: 25,
  },
];

export const PRINTFUL_PRODUCTS = [
  {
    id: 1,
    thumbnail_url: "https://source.unsplash.com/user/c_v_r/100x100",
    product_name: "Cosmetics",
  },
  {
    id: 2,
    thumbnail_url: "https://source.unsplash.com/user/c_v_r/100x100",
    product_name: "Electronics",
  },
];
