import React, { useContext, useEffect, useState } from "react";
import NestedDropdown from "./NestedDropdown";
import axios from "axios";
import { parseCategoriesData } from "../utils";
import { AppContext } from "../App";
import { Link } from "react-router-dom";
import { BASE_URL } from "../Adapters";

const NavBottom = () => {
  const { setCategories } = useContext(AppContext);
  const [dropdownCategories, setDropDownCategories] = useState([]);
  const fetchAPI = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}product/api/categories/`
      );
      if (response.data.length > 0) {
        setCategories(response.data);
        const decoratedData = parseCategoriesData(response.data);
        const newData = decoratedData.filter(
          (cat) => cat.category_id !== 8 && cat.category_id !== 9
        );
        setDropDownCategories(newData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAPI();
  }, []);
  return (
    <div
      className={
        window.location.pathname === "/" ? "bottom_nav-home" : "bottom_nav"
      }
    >
      <div className="container">
        <div className="row">
          <div className="col-auto align-self-center">
            <span>
            <Link to={'/limited_designs'}>Limited Time Designs</Link>
              {/* <a href=" url 'home:limited_designs' ">Limited Time Designs</a> */}
            </span>
          </div>
          {dropdownCategories.length > 0 &&
            dropdownCategories.map((category, index) => (
              <div className="col-auto list_dropdown">
                <div className="dropdown-center">
                  <NestedDropdown category={category} key={index} />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default NavBottom;
