import React from "react";
import "../styles/contact_us.css";

const ContactUs = () => {
  return (
    <>
      <div className="top_heading">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-auto">
              <h3>Contact us</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="container-md contact_container">
        <h2 className="top_heading_name">Contact us</h2>
        <div className="row flex_row">
          <div className="col-lg-4">
            <div className="row left_side">
              <div className="col-6 col-md-12 main_box">
                <div className="box-contact-us">
                  <img
                    className="img-fluid"
                    src={require("../assets/icons/office.png")}
                    alt="x"
                  />
                  <div className="contact_content">
                    <h5>Our Main Office</h5>
                    <p>Dexter sol , Lahore , Pakistan</p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-12 main_box">
                <div className="box-contact-us">
                  <img
                    className="img-fluid"
                    src={require("../assets/icons/phone.png")}
                    alt="x"
                  />
                  <div className="contact_content">
                    <h5>Phone Number</h5>
                    <p>+(123) 14567891011</p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-12 main_box">
                <div className="box-contact-us">
                  <img
                    className="img-fluid"
                    src={require("../assets/icons/fax.png")}
                    alt=""
                  />
                  <div className="contact_content">
                    <h5>Fax</h5>
                    <p>1-234-567-8900</p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-12 main_box">
                <div className="box-contact-us">
                  <img
                    className="img-fluid"
                    src={require("../assets/icons/email.png")}
                    alt=""
                  />
                  <div className="contact_content">
                    <h5>Email</h5>
                    <p>info@imaginedesigns.co</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 right_side">
            <h5>Get In Touch with us for more Information</h5>
            <div className="row">
              <div className="col">
                <span>First Name</span>
                <br />
                <input type="text" placeholder="Enter Name" />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="row my-0">
                  <div className="col">
                    <span>Email</span>
                    <br />
                    <input type="text" placeholder="Enter Email Address" />
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="row my-0">
                  <div className="col">
                    <span>Subject</span>
                    <br />
                    <input type="text" placeholder="Enter Subject" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <span>Message</span>
                <br />
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="7"
                  placeholder="Enter Message"
                ></textarea>
              </div>
            </div>
            <div className="send_message text-lg-end text-center">
              <button>Send Message</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
