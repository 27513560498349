import React from 'react'
import MainLayout from '../layouts/MainLayout';
import AboutUsBody from '../containers/AboutUs';

const AboutUs = () => {
	return (
		<MainLayout>
			<AboutUsBody />
		</MainLayout>
	)
}

export default AboutUs