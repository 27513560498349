import React, { useState } from "react";
import styles from "../styles/login.module.css";
import { Link, useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { parseJwt } from "../utils";
import { toast } from "react-toastify";
import { BASE_URL } from "../Adapters";

const Login = () => {
  const navigate = useNavigate();

  // states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // login function
  const handleLogin = async () => {
    if (validateEmail(email)) {
      try {
        const response = await axios.post(
          `${BASE_URL}auth/api/login/`,
          {
            email,
            password,
          }
        );
        if (response.status) {
          let userData = {
            email: email,
            username: response?.data?.response?.data?.username,
            token: response?.data?.response?.token
          }
          toast.success("Login Successfully")
          localStorage.setItem('userData', JSON.stringify(userData))
          navigate("/");
        }
      } catch (error) {
        if (error.response.status == 404) {
          toast.error("Invalid Credentials!", { toastId: 'toast' })
        }
        console.log(error);
      }
    } else {
      toast.error("Email is invalid.", { toastId: 'toast' })
    }
  };

  // signup function from google
  const handleSignUp = async (userEmail, userName) => {
    let payload = {
      username: userName,
      email: userEmail,
      password: userEmail ? "Imagine" : password,
    }
    if (userEmail) {
      payload = {
        ...payload,
        iss: true
      }
    }
    try {
      const response = await axios.post(
        `${BASE_URL}auth/api/signup/`,
        payload
      );
      if (response.status && !userEmail) {
        navigate("/login");
      }
    } catch (error) {
      if (error?.response?.status && !userEmail) {
        toast.error('User already exists with this email.')
      } else {
        let userData = {
          email: payload?.email,
          username: payload?.username
        }
        toast.success("Login Successfully")
        localStorage.setItem('userData', userData)
        navigate("/");
      }
      console.log(error);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.form_container}>
        <div className={styles.left}>
          <img
            className={styles.img}
            src={require("../assets/images/login.jpg")}
            alt="login"
          />
        </div>
        <div className={styles.right}>
          <h2 className={styles.from_heading}>Members Log in</h2>
          <input
            type="text"
            className={styles.input}
            placeholder="Email"
            onChange={(event) => setEmail(event.target.value)}
          />
          <input
            type="password"
            className={styles.input}
            placeholder="Password"
            onChange={(event) => setPassword(event.target.value)}
          />
          <button
            className={styles.btn}
            onClick={handleLogin}
            disabled={!email ||
              !password}
          >
            Log In
          </button>
          {/* <p className={styles.text}>or</p>
          <span>
            <GoogleLogin
              onSuccess={async (credentialResponse) => {
                const jwtData = parseJwt(credentialResponse.credential);
                console.log(jwtData);
                // const response = await axios.post(
                //   "http://3.139.100.139/v1/auth/api/login/",
                //   {
                //     ...jwtData,
                //   }
                // );
                handleSignUp(jwtData?.email, jwtData?.name)
                // navigate('/');
              }}
              onError={() => console.log("Login Failed")}
              useOneTap={true}
            />
          </span> */}
          <p className={styles.text}>
            New Here ? <Link to="/signup">Sign Up</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
