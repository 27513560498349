import React from "react";
import MainLayout from "../layouts/MainLayout";
import ContactUsBody from "../containers/ContactUs";

const ContactUs = () => {
  return (
    <MainLayout>
      <ContactUsBody />
    </MainLayout>
  );
};

export default ContactUs;
