import React from "react";
import { useNavigate } from "react-router-dom";

 const Close = ({ fill }) => {
    return (
      <svg
        className="h-[1.125rem] w-[1.125rem]"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.36973 1.42735C1.79418 1.00302 2.36979 0.764648 2.96996 0.764648C3.57014 0.764648 4.14575 1.00302 4.5702 1.42735L12.5736 9.43078L20.5771 1.42735C21.004 1.01505 21.5757 0.786907 22.1692 0.792064C22.7626 0.797221 23.3303 1.03526 23.75 1.45492C24.1696 1.87457 24.4077 2.44227 24.4128 3.03573C24.418 3.62919 24.1898 4.20093 23.7775 4.62782L15.7741 12.6313L23.7775 20.6347C24.1898 21.0616 24.418 21.6333 24.4128 22.2268C24.4077 22.8202 24.1696 23.3879 23.75 23.8076C23.3303 24.2272 22.7626 24.4653 22.1692 24.4704C21.5757 24.4756 21.004 24.2475 20.5771 23.8352L12.5736 15.8317L4.5702 23.8352C4.14331 24.2475 3.57157 24.4756 2.97811 24.4704C2.38465 24.4653 1.81696 24.2272 1.3973 23.8076C0.977645 23.3879 0.739604 22.8202 0.734447 22.2268C0.72929 21.6333 0.957429 21.0616 1.36973 20.6347L9.37317 12.6313L1.36973 4.62782C0.945404 4.20336 0.707031 3.62776 0.707031 3.02758C0.707031 2.4274 0.945404 1.8518 1.36973 1.42735V1.42735Z"
          fill={fill ? fill : "white"}
        />
      </svg>
    );
  };

export default {
    Close
}