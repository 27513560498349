import React, { useEffect, useState } from "react";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";

// css
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";
import "../styles/vendor/doka.min.css";
import { create } from "../styles/vendor/doka.esm.min";
import { toast } from "react-toastify";

// Register the FilePond plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginImageEdit
);

function ImageEditorComponent({ selectedImage, setDesignedImage, setSelectedImageData }) {
  const [file, setFile] = useState(selectedImage);

  useEffect(() => {
    fetchImage();
  }, [selectedImage]);

  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem('selectedImage'))
    if (localData) {
      checkImageUrl(localData?.image_url)
    }
  }, [])

  // check if image url is correct if correct save image else remove image
  const checkImageUrl = async (url) => {
    try {
      const response = await fetch(url, { method: "HEAD" });
      if (!response.ok) {
        toast.error('Image Url is not correct or broken', { toastId: 'toast' })
        setTimeout(() => {
          localStorage.removeItem('selectedImage')
        }, 1000);
        // throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        setTimeout(() => {
          localStorage.removeItem('selectedImage')
        }, 1000);
        return setFile(url);
      }
    } catch (error) {
      // console.error('Error checking image URL:', error);
      return false; // Image doesn't exist or error occurred
    }
  }

  const fetchImage = async () => {
    if (selectedImage) {
      try {
        const response = await fetch(selectedImage);
        const blob = await response.blob();
        const file = new File([blob], 'image.png', { type: blob.type });
        setFile(file);
        setTimeout(() => {
          localStorage.removeItem('selectedImage')
        }, 1000);
      } catch (error) {
        // console.error('Error fetching image:', error);
      }
    }
  };

  const convertToImageUrl = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setDesignedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpdateFiles = (fileItems) => {
    // Update the file state when FilePond's files change
    if (fileItems.length > 0) {
      setFile(fileItems[0].file);
      convertToImageUrl()
    }else{
      setSelectedImageData()
      setDesignedImage()
    }
  };

  useEffect(() => {
    convertToImageUrl()
  }, [file])

  return (
    <div>
      <FilePond
        labelIdle='No file selected to edit'
        allowBrowse={false}
        allowImagePreview={true}
        // files={files}
        files={file ? [file] : []}
        allowMultiple={false}
        allowFileUpload={false}
        onupdatefiles={handleUpdateFiles}
        onpreparefile={(file, output) => {
          convertToImageUrl(output)
        }}
        imageResizeTargetWidth={350}
        imageResizeTargetHeight={350}
        imageResizeUpscale={false}
        imageResizeMode={"contain"}
        imageEditEditor={create({
          cropMinImageWidth: 128,
          cropMinImageHeight: 128
        })}
        style={{ backgroundColor: "white" }}
      />
    </div>
  );
}

export default ImageEditorComponent;
