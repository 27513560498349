import React from "react";
import MainLayout from "../layouts/MainLayout";
import GlobalSearchBody from "../containers/GlobalSearch";

const GlobalSearch = () => {
  return (
    <MainLayout>
      <GlobalSearchBody />
    </MainLayout>
  );
};

export default GlobalSearch;
