import React from "react";
import MainLayout from "../layouts/MainLayout";
import SignUpBody from "../containers/SignUp";

const FAQs = () => {
  return (
    <MainLayout>
      <SignUpBody />
    </MainLayout>
  );
};

export default FAQs;
