function arrayBufferToImage(arrayBuffer) {
  return new Promise((resolve, reject) => {
    const blob = new Blob([arrayBuffer], { type: "image/png" });
    const url = URL.createObjectURL(blob);

    const img = new Image();
    img.onload = () => {
      URL.revokeObjectURL(url);
      resolve(img);
    };
    img.onerror = (error) => reject(error);
    img.src = url;
  });
}

export async function renderArrayBufferAsPNG(arrayBuffer) {
  try {
    const img = await arrayBufferToImage(arrayBuffer);

    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    // Export canvas content as PNG
    const pngDataUrl = canvas.toDataURL("image/png");
    return pngDataUrl;
  } catch (error) {
    console.error("Error rendering array buffer as PNG:", error);
    return null;
  }
}

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function parseCategoriesData(data) {
  return data.map((category) => ({
    category_id: category.id,
    category_name: category.category_name,
    printful_category_id: category.printful_category_id,
    sub_category: category.sub_categories.map((subCategory) => ({
      sub_category_id: subCategory.sub_category_id,
      sub_category_name: subCategory.sub_category_name,
      printful_category_id: subCategory.printful_category_id,
      sub_sub_category: subCategory.sub_sub_categories.map(
        (subSubCategory) => ({
          sub_sub_category_id: subSubCategory.sub_sub_category_id,
          sub_sub_category_name: subSubCategory.sub_sub_category_name,
          printful_category_id: subSubCategory.printful_category_id,
        })
      ),
    })),
  }));
}
