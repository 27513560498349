import React from "react";
import MainLayout from "../layouts/MainLayout";
import EditorBody from "../containers/Editor";

const Editor = () => {
  return (
    <MainLayout>
      <EditorBody />
    </MainLayout>
  );
};

export default Editor;
