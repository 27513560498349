import React, { useEffect, useState } from "react";
import "../styles/limited_designs.css";
import axios from "axios";
import { BASE_URL } from "../Adapters";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const LimitedDesigns = () => {
  const navigate = useNavigate()

  const [data, setData] = useState([])
  const [loader, setLoader] = useState(true)

  const handleClick = (data) => {    let updatedData = {
      image_url: data?.image_url,
      prompt: data?.text,
      previous_image_url: data?.image_url
    }
    localStorage.setItem('selectedImage', JSON.stringify(updatedData))
    toast.success('Please select a product to complete design', { toastId: 'toast' })
    navigate('/')
  }

  const getLimitedDesignData = async () => {
    setLoader(true)
    try {
      const response = await axios.get(
        `${BASE_URL}product/api/limited_design/`
      );
      setLoader(false)
      setData(response?.data?.response?.clientsgroup)
    } catch (error) {
      setLoader(false)
    }
  };

  useEffect(() => {
    // get limited design data
    getLimitedDesignData();
  }, []);

  return (
    <div class="super_main">
      <div class="top_heading">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-auto">
              <h3>Limited Time Designs</h3>
            </div>
          </div>
        </div>
      </div>

      <div class="container-lg">
        <h2 class="top_heading_name">Limited Designs</h2>
        <div class="limited_time_designs">
          {loader ? <div>Data is loading...</div>
            : data?.length > 0 ?
              <div className="row card_products">
                {data?.map((itm, ind) => (
                  <div className="col-sm-6 col-md-3" key={ind}>
                    <div className="card">
                      <div className="product_img">
                        <img
                          className="img-fluid"
                          src={itm?.image_url}
                          alt={`limited_designs-${ind}`}
                        />
                      </div>
                      <div className="p_colors">
                        <div className="card_content">
                          <p>
                            {itm?.text}{" "}
                          </p>
                          <button className="btn_for_card" onClick={() => { handleClick(itm) }}>
                            Use this Image
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              : <div className="">
                No data found.
              </div>}

        </div>
      </div>
    </div>
  );
};

export default LimitedDesigns;
