import React, { useEffect, useState } from 'react';
import '../styles/RadioButton.css'; // Import CSS file for styling

const RadioButton = ({ options, onSelect, selectedValue }) => {
    const [selectedOption, setSelectedOption] = useState(selectedValue);

    const handleOptionChange = (option) => {
        setSelectedOption(option?.value);
        onSelect(option);
    };

    useEffect(() => {
        setSelectedOption(selectedValue);
    }, [selectedValue])

    return (
        <div className="radio-button-container">
            {options?.length > 0 && options?.map((option) => (
                <label key={option} className="radio-label">
                    <input
                        type="radio"
                        value={option?.value}
                        checked={selectedOption === option?.value}
                        onChange={() => handleOptionChange(option)}
                    />
                    {option?.name}
                </label>
            ))}
        </div>
    );
};

export default RadioButton;
