import React, { useContext, useEffect, useState } from "react";
import "../styles/product_detail.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../App";
import axios from "axios";
import { BASE_URL } from "../Adapters";

const ProductDetailsBody = () => {
  const navigate = useNavigate()

  const { productId } = useParams();
  const {
    selectedColor,
    setSelectedColor,
    selectedSize,
    setSelectedSize,
    previewImage,
    selectedPrize,
    setSelectedPrize,
    setPreviewImage,
    productDetail,
    setProductDetail,
    setSelectedVariantId,
  } = useContext(AppContext);

  const [images, setImages] = useState([]);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);

  // get data of the product
  const mountApi = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}product/api/products/category/${productId}/`
      );
      const availableVariants = response.data[0].variants_info?.filter(itm => itm?.availability_status == 'active')
      const availableColors = availableVariants
        ?.map((variant) => variant.color)
        ?.filter(
          (variant, index, copyArray) => copyArray.indexOf(variant) === index
        );
      const availableSizes = availableVariants
        ?.map((variant) => variant.size)
        ?.filter(
          (variant, index, copyArray) => copyArray.indexOf(variant) === index
        );
      const availablePreviewImages = availableVariants
        ?.map((variant) => variant.varient_preview_url)
        ?.filter(
          (variant, index, copyArray) => copyArray.indexOf(variant) === index
        );

      setImages(availablePreviewImages);
      setColors(availableColors);
      setSizes(availableSizes);
      setProductDetail(response.data[0]);
      setPreviewImage(response.data[0].thumbnail_url);
      getVariantId(availableColors[0], availableSizes[0]);
      filterSizes(availableColors[0])
    } catch (error) {
      // console.log(error);
    }
  };

  // get prize and variant id according to size and color
  const getVariantId = async () => {
    if (productDetail && productDetail?.variants_info?.length > 0) {
      const currentVariantId = productDetail?.variants_info?.find(
        (variant) =>
          variant?.color === selectedColor && variant.size === selectedSize
      );
      if (currentVariantId) {
        setSelectedVariantId(currentVariantId?.variant_id);
        setSelectedPrize(currentVariantId?.retail_price);
      }
    }
  };

  // Function to filter sizes according to color in product variant data
  const filterSizes = (color) => {
    const filtered = productDetail?.variants_info?.filter(variant => variant?.color === color)?.filter(itm => itm?.availability_status == 'active');
    const filteredSizesArray = filtered?.map(variant => variant.size)
    setSizes(filteredSizesArray);
  };

  useEffect(() => {
    if (document.querySelector('.topscroll')) {
      document.querySelector('.topscroll').scrollIntoView();
    }
  }, [])

  useEffect(() => {
    // Reset values 
    setSelectedColor('');
    setSelectedSize('');
    setSelectedPrize('');
    setPreviewImage('');
    setProductDetail(null);
    setSelectedVariantId('');
  }, []);

  useEffect(() => {
    // by default select first image and color
    if (!selectedColor && colors?.length > 0) {
      setSelectedColor(colors[0])
      if (images?.length > 0) {
        setPreviewImage(images[0]);
      }
    }
  }, [colors, images])

  useEffect(() => {
    // by default select first size
    if (sizes?.length > 0 && !selectedSize) {
      setSelectedSize(sizes[0]);
    }
  }, [sizes])

  useEffect(() => {
    setSelectedPrize()
    mountApi();
  }, []);

  useEffect(() => {
    setSizes()
    setSelectedSize()
  }, [selectedColor])

  useEffect(() => {
    setSelectedPrize()
    getVariantId(selectedColor, selectedSize);
    filterSizes(selectedColor)
  }, [selectedColor, selectedSize]);

  return (
    <>
      <div className={`container-lg super_main topscroll`}>
        <div className="p_detail_bar">
          <Link to={"/select_items"}>All Products</Link>
          <Link to="/">T-Shirts</Link>
          <span>{productDetail?.product_name}</span>
        </div>

        <div className="row justify-content-center align-items-center product_detail">
          <div className="col-md-5 text-center for_product_img">
            <img
              className="img-fluid"
              src={previewImage}
              alt={productDetail?.product_name}
            />
          </div>
          <div className="col-md-7">
            <h3>{productDetail?.product_name}</h3>
            {/* <div className="rating">
              <img
                className="img-fluid"
                src={require("../assets/icons/star full.png")}
                alt="Full Star-1"
              />
              <img
                className="img-fluid"
                src={require("../assets/icons/star full.png")}
                alt="Full Star-2"
              />
              <img
                className="img-fluid"
                src={require("../assets/icons/star full.png")}
                alt="Full Star-3"
              />
              <img
                className="img-fluid"
                src={require("../assets/icons/star full.png")}
                alt="Full Star-4"
              />
              <img
                className="img-fluid"
                src={require("../assets/icons/star half.png")}
                alt="Half Star-1"
              />
              <span>8499 Reviews</span>
            </div> */}
            <h6 style={{ margin: "2%", fontSize: "18px" }}>
              &#36; {selectedPrize}
            </h6>
            <div className="colors">
              <p style={{ fontWeight: "bold", fontFamily: "Arial" }}>
                Color : {selectedColor}
              </p>
              <h4>Please Select your Color</h4>
              <div className="p_colors">
                {colors?.map((color, index) => (
                  <div
                    className="color_border"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSelectedColor(color);
                      setPreviewImage(images[index]);
                    }}
                  >
                    <input
                      hidden
                      name="colored_img"
                      className="color_input"
                      id={index}
                      value={color}
                    />
                    <img
                      style={{
                        width: "30px",
                        height: "30px",
                        objectFit: "cover",
                        borderRadius: "20px",
                        marginRight: "10%",
                        padding: "3px",
                        ...(selectedColor === color && {
                          border: "2px solid black",
                        }),
                      }}
                      src={images[index]}
                      alt={`selectedVariant-${index}`}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="sizes">
              <h4>Select The Size :</h4>
              <div className="s_btns">
                <div className="size_btns">
                  {selectedColor &&
                    sizes?.length > 0 &&
                    sizes?.map((size, index) => (
                      <button
                        className="selected_size"
                        type="button"
                        onClick={() => {
                          setSelectedSize(size);
                        }}
                        {...(selectedSize === size && {
                          style: {
                            fontWeight: "550",
                            border: "1px solid yellow",
                          },
                        })}
                      >
                        {size}
                      </button>
                    ))}
                </div>
                {/* <div className="s_text">
                  <span>Size Chart</span>
                </div> */}
              </div>
            </div>
          </div>
          <form className="generate_btn text-md-end">
            <input type="hidden" name="variant_color" id="to_send_color" />
            <input type="hidden" name="variant_size" id="to_send_size" />
            <input type="hidden" name="main_product_id" value={productId} />
            {/* <Link to={"/generate_ai_images"}> */}
            <button type="submit" disabled={!selectedColor || !selectedSize} onClick={() => {
              if (localStorage.getItem('selectedImage')) {
                navigate('/editor')
              } else {
                navigate("/generate_ai_images")
              }
            }}>
              Generate Image
            </button>
            {/* </Link> */}
          </form>
        </div>
      </div>
    </>
  );
};

export default ProductDetailsBody;
