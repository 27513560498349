import React, { useContext, useEffect, useRef, useState } from "react";
import "../styles/generate_ai_images.css";
import "../styles/style.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingModal from "../components/LoadingModal";
import { AppContext } from "../App";
// import { renderArrayBufferAsPNG } from "../utils";
import { BASE_URL } from "../Adapters";

const GenerateAI = () => {
  const navigate = useNavigate()
  const {
    previewImage,
    // aiGeneratedImages,
    setAIGeneratedImages,
    // setImage,
    // setBgRemove,
    // setRevertBg,
    // setCurrentImage,
    // image,
    // bgRemove,
  } = useContext(AppContext);
  // const navigate = useNavigate()
  // let loadingRef = useRef(null);

  const userData = JSON.parse(localStorage.getItem('userData'))

  const [imageLoading, setImageLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState("");
  const [dataLoading, setDataLoading] = useState(true)
  const [catelogImages, setCatelogImages] = useState([])
  const [aiImages, setAiImages] = useState([])

  const getCatalogProducts = async () => {
    setDataLoading(true)
    try {
      const headers = {
        headers: {
          'authorization': `Bearer ${userData?.token ? userData?.token : ""}`
        }
      }
      const response = await axios.get(
        `${BASE_URL}product/api/catelog/`, headers
      );
      setCatelogImages(prevImages => {
        const newImages = response?.data.filter(newImage =>
          !prevImages?.some(prevImage => prevImage?.id === newImage?.id)
        );
        const prev = prevImages || []
        return [...prev, ...newImages];
      });
      setDataLoading(false)
      // setAIGeneratedImages([...aiGeneratedImages, ...response?.data]);
    } catch (error) {
      setDataLoading(false)
    }
  };

  useEffect(() => {
    if (!previewImage) {
      navigate('/')
    }
  }, [previewImage])

  useEffect(() => {
    setAIGeneratedImages()
    // get catalog products
    getCatalogProducts();
  }, []);

  // useEffect(() => {
  //   // Close the modal when images are generated
  //   let timeoutId;
  //   if (aiGeneratedImages?.length > 0) {
  //     timeoutId = setTimeout(() => {
  //       // loadingRef.current.style.display = "none";
  //       setCurrentImage(bgRemove);
  //       // We need to pass bgRemove here instead of Image Make sure to replace
  //     }, 10000);
  //   } else if (image) {
  //     setCurrentImage(image);
  //   }
  //   // Clear the timeout if the component unmounts or if images change before timeout
  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, [aiGeneratedImages, image]);

  // useEffect(() => {
  //   const urlScript = document.createElement("script");
  //   urlScript.src =
  //     "https://cdn.jsdelivr.net/npm/@fancyapps/ui@5.0/dist/fancybox/fancybox.umd.js";
  //   urlScript.async = true;
  //   document.body.appendChild(urlScript);

  //   const script = document.createElement("script");
  //   script.src = "/scripts/generateAIImages.js";
  //   script.async = true;
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(urlScript);
  //     document.body.removeChild(script);
  //   };
  // }, []);

  useEffect(() => {
    setAIGeneratedImages([...aiImages, ...catelogImages]);
  }, [catelogImages, aiImages])

  // const handleRemoveBackground = async (imageUrl) => {
  //   const apiKey = "nRjWfVTT33UY1cgkswNZhB5G";
  //   const apiUrl = "https://api.remove.bg/v1.0/removebg";
  //   setImage(imageUrl);

  //   const formData = new FormData();
  //   formData.append("image_url", imageUrl);
  //   formData.append("size", "auto");

  //   axios({
  //     method: "post",
  //     url: apiUrl,
  //     data: formData,
  //     responseType: "arraybuffer",
  //     headers: {
  //       ...(formData.getHeaders
  //         ? formData.getHeaders()
  //         : { "Content-Type": "multipart/form-data" }),
  //       "X-Api-Key": apiKey,
  //     },
  //     encoding: null,
  //   })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         renderArrayBufferAsPNG(response.data).then((pngDataUrl) => {
  //           // console.log("Rendered PNG:", pngDataUrl);
  //           // const blob = new Blob([atob(pngDataUrl.split(",")[1])], {
  //           //   type: "image/png",
  //           // });
  //           // const tempUrl = URL.createObjectURL(blob);
  //           setBgRemove(pngDataUrl);
  //         });
  //       } else {
  //         // console.error("Error:", response.status, response.statusText);
  //       }
  //       setRevertBg(true);
  //     })
  //     .catch((error) => {
  //       // if (error.response) {
  //       //   console.error(
  //       //     "Request failed:",
  //       //     error.response.status,
  //       //     error.response.statusText
  //       //   );
  //       //   console.error("Response data:", error.response.data);
  //       // } else if (error.request) {
  //       //   console.error("No response received:", error.request);
  //       // } else {
  //       //   console.error("Request failed:", error.message);
  //       // }
  //     });
  // };

  const handleSearch = async (event) => {
    event.preventDefault();
    setImageLoading(true);

    try {
      const response = await axios.post(
        "https://api.justimagineapi.org/v1/imagine",
        { msg: searchTerm },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer ad4b3d68-c99c-466e-a615-337fa737be5f",
          },
          redirect: "follow",
        }
      );
      const result = response.data;

      if (result && result.messageId) {
        let progress = 0;

        const checkProgress = async () => {
          try {
            const getImageResponse = await axios.get(
              `https://api.justimagineapi.org/v1/message/${result.messageId}`,
              {
                headers: {
                  Authorization: "Bearer ad4b3d68-c99c-466e-a615-337fa737be5f",
                },
                redirect: "follow",
              }
            );

            const imageResult = getImageResponse.data;
            progress = imageResult.progress;

            if (progress < 100) {
              setTimeout(checkProgress, 15000); // Check progress again after 15 seconds
            } else {
              // Progress is 100%, handle completion
              setImageLoading(false);
              const resultImages = imageResult.response.imageUrls?.map((itm) => ({
                image_url: itm,
                prompt: searchTerm,
                aiGenerated: true,
              }));
              setAiImages([...resultImages]);
            }
          } catch (error) {
            console.error("Error while checking progress:", error);
            setImageLoading(false);
          }
        };

        // Start checking progress
        checkProgress();
      } else {
        setImageLoading(false);
        console.log("messageId not found");
      }
    } catch (error) {
      setImageLoading(false);
      console.error("Error:", error);
    }
  };


  const handleChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  return (
    <>
      <div className="container-lg">
        <div className="p_detail_bar">
          <Link to={"/select_items"}>All Products</Link>
          <Link to="/">T-Shirts</Link>
          <span>Custom Printed Gildan Heavy Cotton T-Shirt</span>
        </div>
        <div className="row justify-content-center gai_content">
          <form className="col-11 col-sm-9 col-lg-6 text-center">
            <h1>
              Generate AI Images for your{" "}
              <span className="rage_text">Product!</span>
            </h1>
            <div className="gai_input">
              <i className="bi bi-search"></i>
              <input
                id=""
                type="text"
                placeholder="Describe what you want...."
                name="prompt"
                required
                value={searchTerm}
                onChange={handleChange}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                  }
                }}
              />
            </div>
            <div className="row justify-content-between ">
              <div className="col-auto limit">
                Single Prompt will generate 4 images
              </div>
              <div
                className="col-auto working"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                How it works?
              </div>
            </div>
            <button
              disabled={!searchTerm}
              id="generate_btn"
              type="button"
              className="gai_buttons"
              onClick={handleSearch}
            >
              Generate
            </button>
          </form>
        </div>

        {dataLoading ? <div>Data is loading....</div> :
          <div class="choose_img text-center">
            {
              aiImages?.length > 0 ? (
                <>
                  {aiImages?.map((image, index) => (
                    <div className="catelog_images_container">
                      <img
                        className="catelog_images"
                        src={image?.image_url ? image?.image_url : image}
                        alt={`ai-generated-${index}`}
                        data-fancybox="gallery"
                      />
                      {!image?.aiGenerated &&
                        <p>{image?.prompt}</p>
                      }
                    </div>
                  ))}
                </>
              ) : ""}
            {
              catelogImages?.length > 0 ? (
                <>
                  {catelogImages?.map((image, index) => (
                    <div className="catelog_images_container">
                      <img
                        className="catelog_images"
                        src={image?.image_url ? image?.image_url : image}
                        alt={`ai-generated-${index}`}
                        data-fancybox="gallery"
                      />
                      {!image?.aiGenerated &&
                        <p>{image?.prompt}</p>
                      }
                    </div>
                  ))}
                </>
              ) : (
                <>
                  No Catelog Images Found.
                  {/* <div className="col-6 col-md-3">
                    <img
                      className="img-fluid"
                      src={
                        "https://cdn.midjourney.com/a4c94695-e5c7-4800-ad75-b78ac0567959/0_0.png"
                      }
                      onClick={() => {
                        // We need to pass bgRemove here instead of Image Make sure to replace
                        handleRemoveBackground(
                          "https://cdn.midjourney.com/a4c94695-e5c7-4800-ad75-b78ac0567959/0_0.png"
                        );
                      }}
                      style={{ cursor: "pointer" }}
                      alt="ai image3"
                      data-fancybox="gallery"
                    />
                  </div>
                  <div className="col-6 col-md-3">
                    <img
                      className="img-fluid"
                      src={
                        "https://cdn.midjourney.com/ba6cc63c-4929-47d3-af6a-f1b7d94bbb7b/0_0.png"
                      }
                      onClick={() => {
                        handleRemoveBackground(
                          "https://cdn.midjourney.com/ba6cc63c-4929-47d3-af6a-f1b7d94bbb7b/0_0.png"
                        );
                      }}
                      style={{ cursor: "pointer" }}
                      alt="ai image3"
                      data-fancybox="gallery"
                    />
                  </div>
                  <div className="col-6 col-md-3">
                    <img
                      className="img-fluid"
                      src={
                        "https://cdn.midjourney.com/cd47ba2a-d33c-4b9c-9eb5-708dbc5942d0/0_0.png"
                      }
                      onClick={() => {
                        handleRemoveBackground(
                          "https://cdn.midjourney.com/cd47ba2a-d33c-4b9c-9eb5-708dbc5942d0/0_0.png"
                        );
                      }}
                      style={{ cursor: "pointer" }}
                      alt="ai image3"
                      data-fancybox="gallery"
                    />
                  </div>
                  <div className="col-6 col-md-3">
                    <img
                      className="img-fluid"
                      src={
                        "https://cdn.midjourney.com/cd47ba2a-d33c-4b9c-9eb5-708dbc5942d0/0_1.png"
                      }
                      onClick={() => {
                        handleRemoveBackground(
                          "https://cdn.midjourney.com/cd47ba2a-d33c-4b9c-9eb5-708dbc5942d0/0_1.png"
                        );
                      }}
                      style={{ cursor: "pointer" }}
                      alt="ai image3"
                      data-fancybox="gallery"
                    />
                  </div> */}
                </>
              )}
          </div>
        }
        <div className="start_designing_btn">
          <Link to="/editor">
            <button type="button" className="gai_buttons">
              Start Designing
            </button>
          </Link>
        </div>
      </div>
      <LoadingModal loading={imageLoading} />
    </>
  );
};

export default GenerateAI;
