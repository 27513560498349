import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { AppContext } from "../App";
import { Alerts } from "../components/Alert";
import { parseCategoriesData } from "../utils";
import { BASE_URL } from "../Adapters";

const Hero = () => {
  const { setCategories } = useContext(AppContext);
  const navigate = useNavigate()

  const [dropdownCategories, setDropDownCategories] = useState([]);
  const [activeTab, setActiveTab] = useState(0)
  const [dataLoader, setDataLoader] = useState(false)
  const [selectedCategoryId, setSelectedCategoryid] = useState()
  const [searchText, setSearchText] = useState("")

  const { products, setProducts } = useContext(AppContext);
  const buttonsRef = useRef([]);

  const handleClick = (index) => {
    buttonsRef.current.forEach((button, i) => {
      if (i === index) {
        button.classList.add("active");
      } else {
        button.classList.remove("active");
      }
    });
  };

  const getPrintFulProducts = async (id, index) => {
    setSelectedCategoryid(id)
    setDataLoader(true)
    setActiveTab(index)
    try {
      const response = await axios.get(
        `${BASE_URL}product/api/category-products/${id}/`
      );
      setDataLoader(false)
      if (response?.data?.length) {
        setProducts(response?.data?.slice(0, 10));
        // navigate(`/category_details/${id}`);
        // Alerts.success("Data Loaded Successfully");
      } else {
        setProducts([]);
        // Alerts.error("No Product Found In this Category");
      }
    } catch (error) {
      setDataLoader(false)
      console.log(error);
    }
    // try {
    //   const response = await axios.get(
    //     "http://3.139.100.139/v1/product/api/products/"
    //   );
    //   setProducts(response.data);
    // } catch (error) {
    //   console.log("Failed to Print from APIs");
    // }
  };

  const fetchAPI = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}product/api/categories/`
      );
      if (response.data.length > 0) {
        setCategories(response.data);
        const decoratedData = parseCategoriesData(response.data);
        const newData = decoratedData.filter(
          (cat) => cat.category_id !== 8 && cat.category_id !== 9
        );
        setDropDownCategories(newData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEnterSubmitSearch = (e) => {
    if (e.key === 'Enter') {
      localStorage.setItem('priceSearch', true)
      e.preventDefault();
      if (searchText?.trim('')?.length > 0) {
        if (window.location.pathname.startsWith('/search')) {
          window.location.href = `/search/${searchText}`;
        } else {
          navigate(`/search/${searchText}`)
        }
      }
    }
  };

  useEffect(() => {
    if (dropdownCategories?.length > 0 && products?.length == 0) {
      getPrintFulProducts(dropdownCategories[0]?.printful_category_id, 0);
      setSelectedCategoryid(dropdownCategories[0]?.printful_category_id)
    }
  }, [dropdownCategories]);

  useEffect(() => {
    fetchAPI();
    setActiveTab(0)
  }, []);

  return (
    <>
      <div className="hero_section">
        <div className="container hero_container">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-7">
              <h1>
                Turning Your Ideas into <span>Reality!</span>
              </h1>
              <p>
                Type a description of any image you can imagine and have our
                powerful AI generator ship it to you on a wide variety of
                clothing and accessories.
              </p>
              <div className="started_btn">
                <button>
                  <a href="#how_to_getstarted">How to get started?</a>
                </button>
              </div>
            </div>
            <div className="col-md-5">
              <img
                className="img-fluid"
                src={require("../assets/images/hero_img.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-lg" id="how_to_getstarted">
        <div className="section_2">
          <div className="row justify-content-center">
            <div className="col-lg-11">
              <div className="row justify-content-evenly">
                <div className="col-11 col-sm-9 col-lg-5 box">
                  <div className="shadow_top">
                    <img
                      src={require("../assets/icons/Group 194.png")}
                      alt=""
                    />
                    <div className="box_content">
                      <h4>1. Choose Your Weapon</h4>
                      <p>
                        Will it be a t shirt, a mug, a pair of socks? The choice
                        is yours! Make your selection and prepare yourself for
                        step two.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="shadow"></div>
                </div>

                <div className="col-11 col-sm-9 col-lg-5 box">
                  <img src={require("../assets/icons/Group 196.png")} alt="" />
                  <div className="box_content">
                    <h4>2. Can’t stop the AI Train</h4>
                    <p>
                      Drop in your own design, or click "Generate Image" and
                      take control of our advanced AI technology. Enter one of
                      an infinite amount of prompts and see the labors of your
                      imagination come to life.{" "}
                    </p>
                  </div>
                  <div className="shadow"></div>
                </div>
                <div className="col-11 col-sm-9 col-lg-5 box">
                  <img src={require("../assets/icons/Group 195.png")} alt="" />
                  <div className="box_content">
                    <h4>3. Cart Wheeling</h4>
                    <p>
                      Launch your creation into the cart like an Olympic
                      Gymnast. Score a perfect 10 at checkout!
                    </p>
                  </div>
                  <div className="shadow"></div>
                </div>
                <div className="col-11 col-sm-9 col-lg-5 box">
                  <img src={require("../assets/icons/Group 197.png")} alt="" />
                  <div className="box_content">
                    <h4>4. Stake Out</h4>
                    <p>
                      Stand guard at your mailbox like a detective on a
                      stakeout. When that package arrives, celebrate like you
                      just won the lottery!
                    </p>
                  </div>
                  <div className="shadow"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="item_selection">
          <h2>Item Selection</h2>
          <div className="segmented-buttons">
            {dropdownCategories.length > 0 &&
              dropdownCategories.map((category, index) => (
                <div className="col-auto list_dropdown">
                  <div className="dropdown-center">
                    <button
                      ref={(el) => (buttonsRef.current[index] = el)}
                      className={`segmented-button ${activeTab == index ? "active" : ""}`}
                      onClick={async () => {
                        await getPrintFulProducts(category.printful_category_id, index)
                      }}
                    >
                      <span>
                        {/* <img src={require("../assets/icons/Frame.png")} alt="" /> */}
                        {category?.category_name}
                      </span>
                    </button>
                  </div>
                </div>
              ))}
          </div>
          {/* <div className="segmented-buttons">
            <button
              ref={(el) => (buttonsRef.current[0] = el)}
              className="segmented-button"
              onClick={() => handleClick(0)}
            >
              <span>
                <img src={require("../assets/icons/Frame.png")} alt="" />
                Women
              </span>
            </button>
            <button
              ref={(el) => (buttonsRef.current[1] = el)}
              className="segmented-button active"
              onClick={() => handleClick(1)}
            >
              <img src={require("../assets/icons/Frame1.png")} alt="" />
              Man
            </button>
            <button
              ref={(el) => (buttonsRef.current[2] = el)}
              onClick={() => handleClick(2)}
              className="segmented-button"
            >
              <img src={require("../assets/icons/Frame2.png")} alt="" />
              Kids
            </button>
            <button
              className="segmented-button"
              ref={(el) => (buttonsRef.current[3] = el)}
              onClick={() => handleClick(3)}
            >
              <img src={require("../assets/icons/Frame3.png")} alt="" />
              Home
            </button>
            <button
              className="segmented-button"
              ref={(el) => (buttonsRef.current[4] = el)}
              onClick={() => handleClick(4)}
            >
              <img src={require("../assets/icons/hat.png")} alt="" />
              Hats
            </button>
            <button
              className="segmented-button"
              ref={(el) => (buttonsRef.current[5] = el)}
              onClick={() => handleClick(5)}
            >
              <img src={require("../assets/icons/Frame4.png")} alt="" />
              Jewelry
            </button>
          </div> */}

          <div className="dropdown_row filter_container">
            <div className="d-flex justify-content-between w-100">
              <div className="d-flex" style={{ flexWrap: "nowrap" }}>
                <div class="email_input">
                  <input type="text" placeholder="Search Price" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} onKeyPress={handleEnterSubmitSearch} />
                </div>
                {/* <div className="select_box">
                  <div className="select_con">
                    <img src={require("../assets/icons/money.png")} alt="" />
                    <select
                      className="form-control"
                      aria-label="Default select example"
                    >
                      <option selected value="">Prices</option>
                      <option value="1">Lowest to highest</option>
                      <option value="2">Highest to lowest</option>
                    </select>
                  </div>
                </div> */}
                {/* <div className="select_box">
                  <div className="select_con">
                    <img src={require("../assets/icons/category.png")} alt="" />
                    <select
                      className="form-control"
                      aria-label="Default select example"
                    >
                      <option selected>Categories</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div> */}
                {/* <div className="select_box">
                  <div className="select_con">
                    <img src={require("../assets/icons/size.png")} alt="" />
                    <select
                      className="form-control"
                      aria-label="Default select example"
                    >
                      <option selected value=''>Sizes</option>
                      <option value="sm">Small</option>
                      <option value="md">Medium</option>
                      <option value="lg">Large</option>
                      <option value="xl">Extra Large</option>
                      <option value="xxl">Extra Extra Large</option>
                    </select>
                  </div>
                </div> */}
              </div>
              {/* <div className="select_box">
                <div className=" select_con">
                  <img src={require("../assets/icons/sort.png")} alt="" />
                  <select
                    className="form-control"
                    aria-label="Default select example"
                  >
                    <option selected>Sort order</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div> */}
            </div>
          </div>
          <div className="row card_products">
            {dataLoader ? <div>Data is loading...</div>
              :
              <>
                {products?.length > 0 ? (
                  products?.map((product) => (
                    <div className="col-sm-6 col-md-3 product_card">
                      <div className="card" data-card-id={product.product_id}>
                        <div className="product_img">
                          <img
                            className="img-fluid"
                            src={product.thumbnail_url}
                            alt={product.product_id}
                            loading="lazy"
                          />
                        </div>

                        <div className="card_below_content">
                          <div className="row justify-content-center p_details">
                            <div className="col-auto">
                              <div className="title mt-4">{product.product_name}</div>
                            </div>
                          </div>
                          <Link to={{ pathname: `/product_details/${product.product_id}` }}>
                            <button type="button" className="btn_for_card">
                              Customize
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    No data Found.
                    {/* <div className="col-sm-6 col-md-3 product_card">
                      <div className="card" data-card-id={"1"}>
                        <div className="product_img">
                          <img
                            className="img-fluid"
                            src={require("../assets/images/T Shirt.png")}
                            alt={"product.modal"}
                          />
                        </div>

                        <div className="card_below_content">
                          <div className="row justify-content-center p_details">
                            <div className="col-auto">
                              <div className="title mt-4">Name</div>
                            </div>
                          </div>
                          <Link to={`/product_details/1`}>
                            <button type="button" className="btn_for_card">
                              Customize
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div> */}
                  </>
                )}
              </>
            }
            {products?.length > 0 &&
              <div className="view_btn text-center">
                <button className="view_more_btn">
                  <Link to={`/category_details/${selectedCategoryId}`}>View More</Link>
                </button>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
