import React from 'react'
import MainLayout from '../layouts/MainLayout'
import GenerateAIBody from '../containers/GenerateAI'

const GenerateAI = () => {
  return (
    <MainLayout>
        <GenerateAIBody />
    </MainLayout>
  )
}

export default GenerateAI