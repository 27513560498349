import React from "react";
import MainLayout from "../layouts/MainLayout";
import TermAndConditionsBody from "../containers/TermsAndConditions";

const TermAndConditions = () => {
  return (
    <MainLayout>
      <TermAndConditionsBody />
    </MainLayout>
  );
};

export default TermAndConditions;
