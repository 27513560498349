import React, { useContext, useEffect, useState } from "react";
import { NavbarSVG } from "../assets/svgs/commonSVGS";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AppContext } from "../App";
import { parseCategoriesData } from "../utils";
import NestedDropdown from "./NestedDropdown";
import { BASE_URL } from "../Adapters";

const Navbar = () => {
  const { searchText } = useParams();
  const location = useLocation()

  const navigate = useNavigate()
  const { setCategories, addToCart } = useContext(AppContext);

  // states
  const [dropdownCategories, setDropDownCategories] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [search, setSearch] = useState('')

  const fetchAPI = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}product/api/categories/`
      );
      if (response.data.length > 0) {
        setCategories(response.data);
        const decoratedData = parseCategoriesData(response.data);
        const newData = decoratedData.filter(
          (cat) => cat.category_id !== 8 && cat.category_id !== 9
        );
        setDropDownCategories(newData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEnterSubmitSearch = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (search?.trim('')?.length > 0) {
        if (location.pathname.startsWith('/search')) {
          window.location.href = `/search/${search}`;
        } else {
          navigate(`/search/${search}`)
        }
      }
    }
  };

  useEffect(() => {
    if (searchText && !search && !localStorage.getItem('priceSearch')) {
      setSearch(searchText)
    }
  }, [searchText]);

  useEffect(() => {
    fetchAPI();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('userData')) {
      setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
    }
  }, [localStorage.getItem('userData')])

  useEffect(() => {
    if (document.querySelector('.topscroll')) {
      document.querySelector('.topscroll').scrollIntoView();
    }
  }, [])

  return (
    <>
      <nav
        className={`topscroll navbar navbar-expand-lg`}
        style={{
          backgroundColor: window.location.pathname === "/" ? "#f6f6f6" : "#ffffff",
        }}
      >
        <div className="container-lg nav_con">
          <Link className="navbar-brand" to={"/"}>
            <div style={{ width: '200px', height: '3.5rem', overflow: 'hidden' }}>
              <img src={`${process.env.PUBLIC_URL}/logo.jpg`} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </div>
          </Link>
          <div className="drawer">
            <div className="drawer_con">
              <Link to={"/cart"}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 16 16" fill="none" className="drawer_cart_img">
                  <path d="M6.5 13C6.77614 13 7 12.7761 7 12.5C7 12.2239 6.77614 12 6.5 12C6.22386 12 6 12.2239 6 12.5C6 12.7761 6.22386 13 6.5 13Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M12.5 13C12.7761 13 13 12.7761 13 12.5C13 12.2239 12.7761 12 12.5 12C12.2239 12 12 12.2239 12 12.5C12 12.7761 12.2239 13 12.5 13Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M2 2H4.18182L5.64364 9.14116C5.69352 9.3867 5.83013 9.60726 6.02957 9.76424C6.22901 9.92121 6.47856 10.0046 6.73455 9.9998H12.0364C12.2923 10.0046 12.5419 9.92121 12.7413 9.76424C12.9408 9.60726 13.0774 9.3867 13.1273 9.14116L14 4.6666H4.72727" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <circle cx="12" cy="4" r="4" fill="yellow" />
                  <text x="12" y="6" text-anchor="middle" fill="black" font-size="6">{addToCart?.length > 0 ? addToCart?.length : 0}</text>
                </svg>
                {/* <img
                  className="drawer_cart_img"
                  src={require("../assets/icons/cart2.png")}
                  alt=""
                /> */}
              </Link>
              <button
                className="btn drawer_toggler_btn"
                style={{ display: "none" }}
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                <img
                  src={require("../assets/icons/toggler.png")}
                  alt="toggler"
                />
              </button>
            </div>

            <div
              className="offcanvas offcanvas-end"
              tabIndex="-1"
              id="offcanvasRight"
              aria-labelledby="offcanvasRightLabel"
            >
              <div className="offcanvas-header">
                <NavbarSVG />
              </div>
              <div className="offcanvas-body">
                {/* <div className="gallery mb-2">
                  <span>
                    <Link to={"/your_orders"}>Gallery</Link>
                  </span>{" "}
                  <br />
                </div> */}
                {/* <span>
                  <Link to={"/limited_designs"}>Limited Time Designs</Link>
                </span> */}
                <div className="container">
                  <div className="">
                    <div className="col-auto align-self-center">
                      <span>
                        <Link to={'/limited_designs'} style={{ fontSize: "15px", padding: "6px 12px", width: '100%' }}>Limited Time Designs</Link>
                        {/* <a href=" url 'home:limited_designs' ">Limited Time Designs</a> */}
                      </span>
                    </div>
                    {dropdownCategories.length > 0 &&
                      dropdownCategories.map((category, index) => (
                        <div className="col-auto list_dropdown">
                          <div className="dropdown-center">
                            <NestedDropdown category={category} key={index} />
                          </div>
                        </div>
                      ))}
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    {isAuthenticated ?
                      <Link className="login-form" onClick={() => {
                        setIsAuthenticated(false)
                        localStorage.removeItem('userData')
                        window.location.href = "/"
                      }}>Logout</Link>
                      : <Link className="login-form" to={'/login'}>Login</Link>
                    }
                  </div>
                </div>
                {/* <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Men's Clothing
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <li>T-shirts</li>
                        <li>Tank Tops</li>
                        <li>Hoodies</li>
                        <li>Shorts</li>
                        <li>Pants</li>
                        <li>Swimwear</li>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Women's Clothing
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <li>T-shirts</li>
                        <li>Tank Tops</li>
                        <li>Hoodies</li>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        kids & Youth
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <li>Hoodies</li>
                        <li>Shorts</li>
                        <li>Pants</li>
                        <li>Swimwear</li>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        Hats
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <li>Hoodies</li>
                        <li>Shorts</li>
                        <li>Pants</li>
                        <li>Swimwear</li>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        Accessories
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <li>Hoodies</li>
                        <li>Shorts</li>
                        <li>Pants</li>
                        <li>Swimwear</li>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        Home & Living
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <li>Hoodies</li>
                        <li>Shorts</li>
                        <li>Pants</li>
                        <li>Swimwear</li>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >
            <form className="d-flex searchbar" role="search">
              <i className="bi bi-search"></i>
              <input
                className="form-control"
                // type="search"
                placeholder="Search..."
                // aria-label="Search"
                onChange={(e) => { setSearch(e.target.value) }}
                value={search}
                onKeyPress={handleEnterSubmitSearch}
              />
            </form>
            <div className="search_imgs">
              {/* <Link to={"/select_items"}>
                <img src={require("../assets/icons/gallery.png")} alt="" />
              </Link> */}
              <Link to={"/cart"}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 16 16" fill="none" >
                  <path d="M6.5 13C6.77614 13 7 12.7761 7 12.5C7 12.2239 6.77614 12 6.5 12C6.22386 12 6 12.2239 6 12.5C6 12.7761 6.22386 13 6.5 13Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M12.5 13C12.7761 13 13 12.7761 13 12.5C13 12.2239 12.7761 12 12.5 12C12.2239 12 12 12.2239 12 12.5C12 12.7761 12.2239 13 12.5 13Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M2 2H4.18182L5.64364 9.14116C5.69352 9.3867 5.83013 9.60726 6.02957 9.76424C6.22901 9.92121 6.47856 10.0046 6.73455 9.9998H12.0364C12.2923 10.0046 12.5419 9.92121 12.7413 9.76424C12.9408 9.60726 13.0774 9.3867 13.1273 9.14116L14 4.6666H4.72727" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <circle cx="12" cy="4" r="4" fill="yellow" />
                  <text x="12" y="6" text-anchor="middle" fill="black" font-size="6">{addToCart?.length > 0 ? addToCart?.length : 0}</text>
                </svg>
                {/* <img src={require("../assets/icons/cart.png")} alt="" /> */}
              </Link>
              {isAuthenticated ?
                <Link className="login-form" onClick={() => {
                  setIsAuthenticated(false)
                  localStorage.removeItem('userData')
                  window.location.href = "/"
                }}>Logout</Link>
                : <Link className="login-form" to={'/login'}>Login</Link>
              }
            </div>
          </div>
        </div>
      </nav>
      <div className="nav_div"></div>
    </>
  );
};

export default Navbar;
