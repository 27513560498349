import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/generate_your_design.css";
import { AppContext } from "../App";
import ImageEditorComponent from "../components/ImageEditorComponent";
import axios from "axios";
import { toast } from "react-toastify";
import SimplePopup from "../components/SimplePopup";
import { BASE_URL, BG_REMOVAL_BASE_URL } from "../Adapters";
import ToggleButton from "../components/ToggleButton";

const Editor = () => {
  const navigate = useNavigate();
  const {
    aiGeneratedImages,
    setAIGeneratedImages,
    setRevertBg,
    revertBg,
    selectedColor,
    selectedSize,
    previewImage,
    selectedPrize,
    selectedVariantId,
    setAddToCart,
    productDetail,
    addToCart,
  } = useContext(AppContext);

  const userData = JSON.parse(localStorage.getItem('userData'))

  const [mockUpGenerated, setMockUpGenerated] = useState("");
  const [selectedImageData, setSelectedImageData] = useState()
  const [designedImage, setDesignedImage] = useState()
  const [activeTab, setActiveTab] = useState('EditYourDesign')
  const [bgRemovalPopup, setBgRemovalPopup] = useState()
  const [bgRemoval, setBgRemoval] = useState()

  useEffect(() => {
    if (!previewImage) {
      navigate('/')
    }
  }, [previewImage])

  const handleSubmit = () => {
    const cartValues = {
      color: selectedColor,
      size: selectedSize,
      preview: mockUpGenerated,
      id: selectedVariantId,
      retail_price: selectedPrize,
      quantity: 1,
      product_name: productDetail?.product_name,
      image_url: selectedImageData?.removedBgImage ? selectedImageData?.removedBgImage : selectedImageData?.image_url,
      mock_generated_image :selectedImageData?.mock_generated_image
    };
    if (addToCart.length) {
      setAddToCart([...addToCart, cartValues]);
    } else {
      setAddToCart([cartValues]);
    }
    navigate("/cart");
  };

  // api calling to remove bg
  const removeBgApi = async () => {
    setBgRemoval(true)
    try {
      const response = await axios.post(
        // `${BASE_URL}image/bg_image/`,
        `${BG_REMOVAL_BASE_URL}/v1/image/bg_image/`,
        {
          rembg: selectedImageData?.image_url
          // image_base64: bgRemovalData,
        }
      );
      if (response.status == 200) {
        setBgRemoval(false)
        setSelectedImageData({
          image_url: `${response?.data?.image_url}`,
          removedBgImage: `${response?.data?.image_url}`,
          prompt: selectedImageData?.prompt,
          previous_image_url: selectedImageData?.previous_image_url,
          removeBackground: true,
        })
        setBgRemovalPopup()
      }
    } catch (error) {
      setBgRemoval(false)
      // console.log(error);
    }
  }

  const postCatalogProduct = async () => {
    try {
      const headers = {
        headers: {
          'authorization': `Bearer ${userData?.token ? userData?.token : ""}`
        }
      }
      const response = await axios.post(
        `${BASE_URL}product/api/catelog/`,
        selectedImageData, headers
      );
    } catch (error) {

      // console.log(error);
    }
  };

  const fetchAPI = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}product/api/preview_image/`,
        {
          preview_image: designedImage,
          varient_id: selectedVariantId,
        }
      );
      if (response.data.message) {
        setSelectedImageData((prev) => ({
          ...prev,
          mock_generated_image: response?.data?.image_url
        }))
        setMockUpGenerated(response.data.message);
      }
    } catch (error) {
      if (error?.response?.status == 429) {
        toast.error(error?.response?.data?.TimeWait, { toastId: 'toast' })
      }
    }
  };

  const getCatalogProducts = async () => {
    try {
      const headers = {
        headers: {
          'authorization': `Bearer ${userData?.token ? userData?.token : ""}`
        }
      }
      const response = await axios.get(
        `${BASE_URL}product/api/catelog/`, headers
      );
      setAIGeneratedImages(prevImages => {
        const newImages = response?.data.filter(newImage =>
          !prevImages?.some(prevImage => prevImage?.id === newImage?.id)
        );
        const prev = prevImages || []
        return [...prev, ...newImages];
      });
      // setAIGeneratedImages([...aiGeneratedImages, ...response?.data]);
    } catch (error) {
    }
  };

  const handleChangeTabToCart = () => {
    if (localStorage.getItem('imageBroken')) {
      toast.error('Image Url is not correct or broken', { toastId: 'toast' })
    } else if (!designedImage) {
      toast.error('Select an image to continue', { toastId: 'toast' })
    } else {
      setActiveTab('AddToCart')
      postCatalogProduct()
      fetchAPI();
    }
  }

  useEffect(() => {
    if (aiGeneratedImages?.length == 0 || localStorage.getItem('selectedImage')) {
      const selectedImage = JSON.parse(localStorage.getItem('selectedImage'))
      setSelectedImageData({
        ...selectedImage,
        removeBackground: false
      })
      // get catalog products
      getCatalogProducts();
    }
  }, []);

  useEffect(() => {
    if (document.querySelector('.topscroll')) {
      document.querySelector('.topscroll').scrollIntoView();
    }
  }, [])

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/scripts/editor.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div class="editor topscroll">
        <input id="zoom-value" type="hidden" value="1" />
        <div class="sidebar">
          <div class="sidebar_con">
            <div
              class="nav nav-pills"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <div
                class={`tabs-design`}
                onClick={async () => { setMockUpGenerated(); setActiveTab('EditYourDesign') }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                >
                  <g clip-path="url(#clip0_330_702)">
                    <path
                      d="M17.6003 9.60423H17.6133M11.101 26.7752H5.90153C4.8673 26.7752 3.87543 26.3578 3.14411 25.6146C2.4128 24.8715 2.00195 23.8636 2.00195 22.8127V6.96254C2.00195 5.91161 2.4128 4.90372 3.14411 4.1606C3.87543 3.41748 4.8673 3 5.90153 3H21.4998C22.5341 3 23.5259 3.41748 24.2573 4.1606C24.9886 4.90372 25.3994 5.91161 25.3994 6.96254V13.5668"
                      stroke={`${activeTab == "EditYourDesign" ? "#fcfb00" : "#D4D4D4"}`}
                      stroke-width="2.295"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.00195 20.171L8.50125 13.5668C9.70752 12.3873 11.1946 12.3873 12.4008 13.5668L13.7007 14.8876M16.3004 26.7753V21.4919C16.3004 20.7913 16.5743 20.1193 17.0618 19.6239C17.5494 19.1285 18.2106 18.8502 18.9001 18.8502C19.5896 18.8502 20.2509 19.1285 20.7384 19.6239C21.2259 20.1193 21.4998 20.7913 21.4998 21.4919V26.7753M16.3004 24.1336H21.4998M25.3994 18.8502V26.7753"
                      stroke={`${activeTab == "EditYourDesign" ? "#fcfb00" : "#D4D4D4"}`}
                      stroke-width="2.295"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_330_702">
                      <rect
                        width="28.6874"
                        height="27.5399"
                        fill="white"
                        transform="translate(0.015625 0.782257)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div className={`${activeTab == "EditYourDesign" ? "activeTab" : "notActiveTab"}`}>
                  Edit Your Design
                </div>
              </div>
              {/* <div
                class={`tabs-design`}
                onClick={() => { handleChangeTabToCart() }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29"
                  height="26"
                  viewBox="0 0 29 26"
                  fill="none"
                >
                  <path
                    d="M28.6852 4.28429C28.7364 4.49961 28.6718 4.73721 28.5638 4.92284L25.1189 10.8777C24.9911 11.1079 24.7226 11.2341 24.4983 11.2341C24.4344 11.2341 24.2554 11.2192 24.1858 11.197L21.4247 10.4099V24.5174C21.4247 24.948 21.1627 25.2599 20.7856 25.2599H7.36385C6.98676 25.2599 6.72472 24.948 6.72472 24.5174V10.4099L4.24489 11.2044C3.95728 11.3083 3.70802 11.1747 3.54824 10.8851L0.128892 4.94511C0.0202394 4.75206 -0.0117172 4.53674 0.0394132 4.32142C0.0841524 4.09867 0.21837 3.95017 0.390935 3.84622L7.36385 0.0149231H10.5595C10.9366 0.0149231 11.1986 0.319348 11.1986 0.757422C11.1986 2.28697 13.0457 3.37102 14.3623 3.37102C15.6789 3.37102 17.5899 2.29439 17.5899 0.757422C17.5899 0.326773 17.852 0.0149231 18.2291 0.0149231H21.4247L28.3273 3.77197C28.5063 3.87592 28.6341 4.06154 28.6852 4.28429Z"
                    fill={`${activeTab == "AddToCart" ? "#fcfb00" : "#D4D4D4"}`}
                  />
                </svg>
                <div className={`${activeTab == "AddToCart" ? "activeTab" : "notActiveTab"}`}>
                  Add To Cart
                </div>

              </div> */}
            </div>
          </div>
        </div>
        {activeTab == 'EditYourDesign' &&
          <ImageEditorComponent
            selectedImage={selectedImageData?.image_url ? selectedImageData?.image_url : null}
            setDesignedImage={setDesignedImage}
            setSelectedImageData={setSelectedImageData}
          />
        }
        <div class="all_content">
          <div class="row">
            <div class="col-12 text-center canvas_part">
              {activeTab == 'EditYourDesign' &&
                <div class="container">
                  <div>
                    <ToggleButton
                      title={'Remove Background'}
                      checked={selectedImageData?.removeBackground ? true : false}
                      // onClick={() => { setSelectedImageData(bgRemovalPopup); setBgRemovalPopup(false) }}
                      onClick={() => {
                        if (selectedImageData?.image_url) {
                          if (selectedImageData?.removeBackground) {
                            setSelectedImageData({
                              ...selectedImageData,
                              image_url: selectedImageData?.previous_image_url,
                              removeBackground: false
                            })
                          } else {
                            if (selectedImageData?.removedBgImage) {
                              setSelectedImageData({
                                ...selectedImageData,
                                image_url: selectedImageData?.removedBgImage,
                                removeBackground: true
                              })
                            } else {
                              removeBgApi()
                            }
                          }
                        } else {
                          toast.error('Select an image first', { toastId: 'toast' })
                        }
                      }}
                    />
                  </div>
                  <div className='parentToggle'>
                    <button
                      class="revert_bg"
                      onClick={() => { handleChangeTabToCart() }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="29"
                        height="26"
                        viewBox="0 0 29 26"
                        fill="none"
                      >
                        <path
                          d="M28.6852 4.28429C28.7364 4.49961 28.6718 4.73721 28.5638 4.92284L25.1189 10.8777C24.9911 11.1079 24.7226 11.2341 24.4983 11.2341C24.4344 11.2341 24.2554 11.2192 24.1858 11.197L21.4247 10.4099V24.5174C21.4247 24.948 21.1627 25.2599 20.7856 25.2599H7.36385C6.98676 25.2599 6.72472 24.948 6.72472 24.5174V10.4099L4.24489 11.2044C3.95728 11.3083 3.70802 11.1747 3.54824 10.8851L0.128892 4.94511C0.0202394 4.75206 -0.0117172 4.53674 0.0394132 4.32142C0.0841524 4.09867 0.21837 3.95017 0.390935 3.84622L7.36385 0.0149231H10.5595C10.9366 0.0149231 11.1986 0.319348 11.1986 0.757422C11.1986 2.28697 13.0457 3.37102 14.3623 3.37102C15.6789 3.37102 17.5899 2.29439 17.5899 0.757422C17.5899 0.326773 17.852 0.0149231 18.2291 0.0149231H21.4247L28.3273 3.77197C28.5063 3.87592 28.6341 4.06154 28.6852 4.28429Z"
                          fill={`${activeTab == "AddToCart" ? "#fcfb00" : "#000"}`}
                        />
                      </svg>
                      Add To Cart
                    </button>
                  </div>
                </div>}
            </div>
            <div class="col-12 right_side_design product_detail">
              <div class="tab-content">
                {activeTab == 'AddToCart' &&
                  <div
                  >
                    <div class="row justify-content-center for_flex_direction">
                      <div class="p_img_con mt-4 mt-md-0 col-md-7">
                        <div class="p_heading">Product Details</div>
                        <div class="p_detail">
                          <div class="row justify-content-center">
                            <div class="col-12 ">
                              <div class="row">
                                <div class="col">
                                  <div class="p_heading">Selected Item</div>
                                  <div class="editor_img_container">
                                    <img
                                      style={{ maxWidth: '100%', maxHeight: '250px', width: 'auto' }}
                                      class="img-fluid"
                                      src={previewImage}
                                      alt="preview_image_selected"
                                    />
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="p_heading">Your Design</div>
                                  <div
                                    // id="designed_img"
                                    class="editor_img_container"
                                  >
                                    <img
                                      style={{ maxWidth: '100%', maxHeight: '250px', width: 'auto' }}
                                      id="your_design"
                                      class="img-fluid"
                                      src={designedImage}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="product_indetails mt-3">
                            <h3>{productDetail.product_name}</h3>
                            <div class="price">
                              &#36;<span>{selectedPrize}</span>
                            </div>
                            <div class="colors mb-3">
                              <h4>Selected Color</h4>
                              <div class="p_colors">
                                <div class="color_border first_border">
                                  <img
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      objectFit: "cover",
                                      borderRadius: "20px",
                                      marginRight: "10%",
                                      padding: "3px",
                                      border: "2px solid black",
                                    }}
                                    src={previewImage}
                                    alt={`selectedImage`}
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="sizes">
                              <h4>Selected Size</h4>
                              <div
                                class="s_btns"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div class="size_btns">
                                  <button class="active">{selectedSize}</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <form
                            class="generate_btn text-center"
                            onSubmit={handleSubmit}
                          >
                            <input
                              type="hidden"
                              name="your_design"
                              id="your_design_input"
                            />
                            <input
                              type="hidden"
                              value=""
                              name="printful_mockup"
                              id="printful_mockup"
                            />
                            <input
                              id="cart_btn"
                              disabled={!mockUpGenerated}
                              type="submit"
                              value="Add to cart"
                              name="cart_btn"
                            />
                          </form>
                        </div>
                      </div>
                      <div class="col-9 col-md-4 text-center">
                        <div class="p_heading">Preview</div>
                        <div class="Preview">
                          {mockUpGenerated ? (
                            <div class="generate_preview_con">
                              <img
                                class="img-fluid preview_bg_image"
                                src={mockUpGenerated}
                                alt=""
                              />
                            </div>
                          ) : (
                            <img
                              class="img-fluid preview_bg_image"
                              src={require("../assets/gif/Spinner-1s-200px.gif")}
                              alt=""
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {activeTab == 'EditYourDesign' &&
                  <div
                  >
                    <div class="p_heading">Choose Your Imagination</div>
                    <div class="choose_img text-center">
                      {!!aiGeneratedImages.length ? (
                        aiGeneratedImages?.map((image, index) => (
                          <div className="catelog_images_container">
                            <img
                              className="catelog_images"
                              src={image?.image_url ? image?.image_url : image}
                              alt={`ai-generated-${index}`}
                              onClick={() => {
                                setSelectedImageData({
                                  image_url: image?.image_url,
                                  prompt: image?.prompt,
                                  previous_image_url: image?.image_url,
                                  removeBackground: false
                                })
                              }}
                            />
                          </div>
                        ))
                      ) : (
                        <>
                          No Data Found.
                        </>
                      )}
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      {!bgRemoval && bgRemovalPopup?.image_url &&
        <SimplePopup>
          <h1 style={{ fontSize: "16px" }}>Do you want to Remove the Background for this Image?</h1>
          <div className="row">
            <div className="col-sm-12 col-md-4 mb-2 mb-md-0">
              <button className="submit-btn"
                onClick={() => { setSelectedImageData(bgRemovalPopup); setBgRemovalPopup(false) }}
              // onClick={() => { handleRemoveBackgroundImage() }}   < -------- Open this for bg removal api -------- >
              >
                Yes
              </button>
            </div>
            <div className="col-sm-12 col-md-4">
              <button className="cancel-btn" onClick={() => { setSelectedImageData(bgRemovalPopup); setBgRemovalPopup(false) }}>
                No
              </button>
            </div>
            <div className="col-sm-12 col-md-4">
              <button className="cancel-btn" onClick={() => { setBgRemovalPopup(false) }}>
                Cancel
              </button>
            </div>
          </div>
        </SimplePopup>
      }

      {bgRemoval &&
        <SimplePopup>
          <h1 style={{ fontSize: "16px" }}>Removing background for this image</h1>
        </SimplePopup>
      }
    </>
  );
};

export default Editor;
