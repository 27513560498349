import React, { useState } from "react";
import styles from "../styles/signup.module.css";
import { Link, useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { parseJwt } from "../utils";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../Adapters";

const SignUp = () => {
  const navigate = useNavigate();

  // states
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // signup function
  const handleSignUp = async (userEmail, userName) => {
    let payload = {
      username: userName ? userName : username,
      email: userEmail ? userEmail : email,
      password: userEmail ? "Imagine" : password,
    }
    if (userEmail) {
      payload = {
        ...payload,
        iss: true
      }
    }
    if (validateEmail(payload?.email)) {
      try {
        const response = await axios.post(
          `${BASE_URL}auth/api/signup/`,
          payload
        );
        if (response.status && !userEmail) {
          toast.success("Account created successfully", { toastId: 'toast' })
          navigate("/login");
        }
      } catch (error) {
        if (error?.response?.status && !userEmail) {
          toast.error('User already exists with this email.', { toastId: 'toast' })
        } else {
          let userData = {
            email: payload?.email,
            username: payload?.username
          }
          toast.success("Login Successfully")
          localStorage.setItem('userData', userData)
          navigate("/");
        }
        console.log(error);
      }
    } else {
      toast.error("Email is invalid.", { toastId: 'toast' })
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.form_container}>
        <div className={styles.left}>
          <img
            className={styles.img}
            src={require("../assets/images/signup.jpg")}
            alt="signup"
          />
        </div>
        <div className={styles.right}>
          <h2 className={styles.from_heading}>Create Account</h2>
          <input
            type="text"
            className={styles.input}
            placeholder="Username"
            onChange={(event) => setUserName(event.target.value)}
          />
          <input
            type="text"
            className={styles.input}
            placeholder="Email"
            onChange={(event) => setEmail(event.target.value)}
          />
          <input
            type="password"
            className={styles.input}
            placeholder="Password"
            onChange={(event) => setPassword(event.target.value)}
          />
          <button
            className={styles.btn}
            onClick={() => { handleSignUp() }}
            disabled={!email ||
              !username ||
              !password}>
            Sign Up
          </button>
          {/* <p className={styles.text}>or</p>
          <span>
            <GoogleLogin
              onSuccess={async (credentialResponse) => {
                const jwtData = parseJwt(credentialResponse.credential);
                // const response = await axios.post(
                //   "http://3.139.100.139/v1/auth/api/signup/",
                //   {
                //     ...jwtData,
                //   }
                // );
                handleSignUp(jwtData?.email, jwtData?.name)
                console.log(jwtData);
              }}
              onError={() => console.log("Login Failed")}
              useOneTap={true}
            />
          </span> */}
          <p className={styles.text}>
            Already Have Account ? <Link to="/login">Log In</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
