import React from 'react';
import '../styles/ToggleButton.css';

const ToggleSwitch = ({ checked, onClick, title }) => {
    return (
        <div className='parentToggle'>
            {title &&
                <h1>{title}</h1>
            }
            <div className={`toggle-switch ${checked ? 'on' : 'off'}`} onClick={onClick && onClick}>
                <div className="toggle-knob"></div>
            </div>
        </div>
    );
};

export default ToggleSwitch;

