import React, { useEffect, useState } from "react";
import "../styles/limited_designs.css";
import axios from "axios";
import { BASE_URL } from "../Adapters";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const GlobalSearchBody = () => {
    const { searchText } = useParams();
    const navigate = useNavigate()

    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true)

    const handleClick = (data) => {
        let updatedData = {
            image_url: data?.image_url,
            prompt: data?.text,
            previous_image_url: data?.image_url
        }
        localStorage.setItem('selectedImage', JSON.stringify(updatedData))
        toast.success('Please select a product to complete design', { toastId: 'toast' })
        navigate('/')
    }

    const getSearchData = async () => {
        setLoader(true)
        try {
            let url
            if (localStorage.getItem('priceSearch')) {
                url = `${BASE_URL}product/api/product-search/?price=${searchText}`
            } else {
                url = `${BASE_URL}product/api/product-search/?name=${searchText}`
            }
            const response = await axios.get(url);
            setLoader(false)
            localStorage.removeItem('priceSearch')
            setData(response?.data)
        } catch (error) {
            setLoader(false)
        }
    };

    useEffect(() => {
        if (document.querySelector('.topscroll')) {
          document.querySelector('.topscroll').scrollIntoView();
        }
      }, [])
    

    useEffect(() => {
        if (!searchText) {
            navigate("/")
        } else {
            // get search data
            getSearchData();
        }
    }, []);

    return (
        <div className="container-lg topscroll" id="how_to_getstarted">
            <div className="item_selection">
                <div className="row card_products" style={{ marginTop: '10px' }}>
                    {loader ? <div>Data is loading...</div>
                        :
                        <>
                            {data?.length > 0 ? (
                                data?.map((product) => (
                                    <div className="col-sm-6 col-md-3 product_card">
                                        <div className="card" data-card-id={product.product_id}>
                                            <div className="product_img">
                                                <img
                                                    className="img-fluid"
                                                    src={product.thumbnail_url}
                                                    alt={product.product_id}
                                                    loading="lazy"
                                                />
                                            </div>

                                            <div className="card_below_content">
                                                <div className="row justify-content-center p_details">
                                                    <div className="col-auto">
                                                        <div className="title mt-4">{product.product_name}</div>
                                                    </div>
                                                </div>
                                                <Link to={{ pathname: `/product_details/${product.product_id}` }}>
                                                    <button type="button" className="btn_for_card">
                                                        Customize
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <>
                                    No data Found.
                                </>
                            )}
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default GlobalSearchBody;
