import React from "react";
import MainLayout from "../layouts/MainLayout";
import LoginBody from "../containers/Login";

const FAQs = () => {
  return (
    <MainLayout noNavbar={false}>
      <LoginBody />
    </MainLayout>
  );
};

export default FAQs;
