import React from 'react'
import MainLayout from '../layouts/MainLayout'
import CheckoutFormBody from '../containers/CheckoutForm'

const CheckoutForm = () => {
  return (
    <MainLayout>
      <CheckoutFormBody />
    </MainLayout>
  )
}

export default CheckoutForm