import React from "react";

export const NavbarSVG = () => {
  return (
    <svg
      style={{ cursor: "pointer" }}
      xmlns="http://www.w3.org/2000/svg"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
      width="30"
      height="30"
      viewBox="0 0 26 26"
      fill="none"
    >
      <circle cx="13" cy="13" r="13" fill="#FCFB00" fillOpacity="0.1" />
      <path
        d="M17.3697 10.2997C17.4414 10.2306 17.4985 10.1478 17.5379 10.0563C17.5772 9.96486 17.598 9.86645 17.5989 9.76687C17.5998 9.66728 17.5809 9.56852 17.5432 9.47633C17.5055 9.38414 17.4499 9.30037 17.3795 9.22992C17.3091 9.15947 17.2254 9.10374 17.1333 9.06598C17.0411 9.02823 16.9423 9.00921 16.8428 9.01003C16.7432 9.01084 16.6448 9.03149 16.5532 9.07075C16.4617 9.11001 16.3789 9.16711 16.3097 9.23871L13.3037 12.2437L10.2987 9.23871C10.23 9.16502 10.1472 9.10592 10.0552 9.06493C9.96325 9.02394 9.86393 9.00189 9.76323 9.00012C9.66253 8.99834 9.5625 9.01686 9.46911 9.05459C9.37572 9.09231 9.29089 9.14845 9.21967 9.21967C9.14845 9.29089 9.09231 9.37572 9.05459 9.46911C9.01686 9.5625 8.99834 9.66253 9.00012 9.76323C9.00189 9.86393 9.02394 9.96325 9.06493 10.0552C9.10592 10.1472 9.16502 10.23 9.23871 10.2987L12.2417 13.3047L9.23671 16.3097C9.10423 16.4519 9.03211 16.6399 9.03553 16.8342C9.03896 17.0285 9.11767 17.2139 9.25509 17.3513C9.3925 17.4887 9.57788 17.5675 9.77219 17.5709C9.96649 17.5743 10.1545 17.5022 10.2967 17.3697L13.3037 14.3647L16.3087 17.3707C16.4509 17.5032 16.6389 17.5753 16.8332 17.5719C17.0275 17.5685 17.2129 17.4897 17.3503 17.3523C17.4877 17.2149 17.5665 17.0295 17.5699 16.8352C17.5733 16.6409 17.5012 16.4529 17.3687 16.3107L14.3657 13.3047L17.3697 10.2997Z"
        fill="black"
      />
    </svg>
  );
};